import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  openDrawer: boolean;
};

export const initialState: InitialState = {
  openDrawer: false
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.openDrawer = !state.openDrawer;
    }
  }
});

export const { toggleDrawer } = layoutSlice.actions;

export default layoutSlice.reducer;
