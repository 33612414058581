import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from 'components/TextField/TextField';
import { t, Trans } from '@lingui/macro';
import { Agent, CreateAgentPayload, UpdateAgentPayload } from 'services/agentServices';
import { textFieldIsEmpty } from 'validator/validator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  header: {
    flex: '0 0 auto',
    margin: 0,
    padding: '16px 24px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  body: {
    flex: '1 1 auto',
    padding: '24px 24px 8px 24px',
    overflowY: 'auto'
  },
  footer: {
    flex: '0 0 auto',
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const functionNameOptions = [
  {
    value: 'ASVP',
    label: 'ASVP'
  },
  {
    value: 'APJ',
    label: 'APJ'
  },
  {
    value: 'APJA',
    label: 'APJA'
  },
  {
    value: 'OPJ',
    label: 'OPJ'
  }
];

const activeOptions = [
  {
    value: t`Oui`,
    label: t`Oui`
  },
  {
    value: t`Non`,
    label: t`Non`
  }
];

export type FormAgentsProps = {
  formTitle: string;
  closeModalHandler: () => void;
  actionType: 'create' | 'update';
  submitHandler?: (agentPayload: CreateAgentPayload | UpdateAgentPayload, id?: number) => Promise<void>;
  data?: Agent;
};

export type FormAgent = Omit<Agent, 'id' | 'created_at' | 'updated_at'>;

const defaultState: FormAgent = {
  agent_id: '',
  name: '',
  surname: '',
  service_code: '',
  function: '',
  enabled: true,
  card_serial: '',
  equipment_serial: ''
};

function FormAgents(props: FormAgentsProps) {
  const [agentData, setAgentData] = React.useState<FormAgent>(defaultState);
  const [agentIdEmpty, setAgentIdEmpty] = React.useState<boolean>(false);
  const [nameEmpty, setNameEmpty] = React.useState<boolean>(false);
  const [surnameEmpty, setSurnameEmpty] = React.useState<boolean>(false);
  const [functionEmpty, setFunctionEmpty] = React.useState<boolean>(false);

  const { formTitle, closeModalHandler, data, actionType, submitHandler } = props;

  const formAgentInError = React.useRef(false);

  const classes = useStyles();

  const closeModal = () => {
    setAgentData(defaultState);
    closeModalHandler();
  };

  const validateRequiredTextField = () => {
    let isValid = true;

    if (textFieldIsEmpty(agentData.agent_id) === true) {
      isValid = false;
      setAgentIdEmpty(true);
    }
    if (textFieldIsEmpty(agentData.name) === true) {
      isValid = false;
      setNameEmpty(true);
    }
    if (textFieldIsEmpty(agentData.surname) === true) {
      isValid = false;
      setSurnameEmpty(true);
    }
    if (textFieldIsEmpty(agentData.function) === true) {
      isValid = false;
      setFunctionEmpty(true);
    }

    return isValid;
  };

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    setAgentData((prev) => ({
      ...prev,
      [fieldName]: fieldValue
    }));
  };

  const submit = () => {
    if (formAgentInError.current === true) {
      return;
    }
    const validation = validateRequiredTextField();
    if (validation === false) {
      return;
    }
    submitHandler && submitHandler(agentData, data && data.id);
    closeModal();
  };

  React.useEffect(() => {
    actionType === 'create' && setAgentData(defaultState);
    actionType === 'update' &&
      data &&
      setAgentData({
        agent_id: data.agent_id,
        name: data.name,
        surname: data.surname,
        service_code: data.service_code,
        function: data.function,
        enabled: true,
        card_serial: data.card_serial,
        equipment_serial: data.equipment_serial
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography component='h2' variant='h6'>
          {formTitle}
        </Typography>
      </div>
      <div className={classes.body}>
        <div>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputAgent_id' }}
                autoFocus
                variant='outlined'
                id='agent_id'
                label={`${t`Matricule`}*`}
                fullWidth
                size='small'
                value={agentData.agent_id}
                error={agentIdEmpty || false}
                helperText={agentIdEmpty ? t`requis` : ''}
                lengthRestriction={30}
                restrictToNumber
                textFieldInError={(inError: boolean) => (formAgentInError.current = inError)}
                onChange={(e) => handleChange('agent_id', e.target.value)}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputFunction' }}
                variant='outlined'
                id='function'
                label={`${t`Fonction`}*`}
                fullWidth
                select
                value={agentData.function}
                error={functionEmpty || false}
                helperText={functionEmpty ? t`requis` : ''}
                lengthRestriction={15}
                textFieldInError={(inError: boolean) => formAgentInError.current === inError}
                onChange={(e) => handleChange('function', e.target.value)}
              >
                {functionNameOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputName' }}
                id='name'
                label={`${t`Nom`}*`}
                fullWidth
                variant='outlined'
                value={agentData.name}
                error={nameEmpty || false}
                helperText={nameEmpty ? t`requis` : ''}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputSurname' }}
                variant='outlined'
                id='surname'
                label={`${`Prénom`}*`}
                value={agentData.surname}
                error={surnameEmpty}
                helperText={surnameEmpty ? t`requis` : ''}
                onChange={(e) => handleChange('surname', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputCard_serial' }}
                variant='outlined'
                id='card_serial'
                label={t`Numéro Carte`}
                fullWidth
                value={agentData.card_serial}
                lengthRestriction={50}
                textFieldInError={(inError: boolean) => formAgentInError.current === inError}
                onChange={(e) => handleChange('card_serial', e.target.value)}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputService_code' }}
                variant='outlined'
                id='service_code'
                label={t`Service code`}
                fullWidth
                lengthRestriction={11}
                restrictToNumber
                value={agentData.service_code}
                onChange={(e) => handleChange('service_code', e.target.value)}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputEnabled' }}
                variant='outlined'
                id='enabled'
                label={t`Actif`}
                fullWidth
                select
                value={agentData.enabled ? t`Oui` : t`Non`}
                onChange={(e) => {
                  handleChange('enabled', e.target.value === t`Oui` ? true : false);
                }}
              >
                {activeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <TextField
                inputProps={{ 'data-testid': 'inputEquipment_serial' }}
                variant='outlined'
                id='equipment_serial'
                label={t`Num série équipement`}
                fullWidth
                value={agentData.equipment_serial}
                lengthRestriction={50}
                onChange={(e) => handleChange('equipment_serial', e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.footer}>
        <Button variant='contained' onClick={closeModal} color='primary'>
          <Trans>Annuler</Trans>
        </Button>
        <Button data-testid='modalUpdateCreateConfirmButton' style={{ marginLeft: 8 }} variant='contained' color='primary' onClick={submit}>
          <Trans>Valider</Trans>
        </Button>
      </div>
    </div>
  );
}

export default FormAgents;
