export type SortOrder = {
  name: string;
  direction: string;
};

export type Temp = {
  column: string;
  order: string;
  data: any[];
};

export type Pagination = {
  page: number;
  rowsPerPage: number;
};

/**
 * function for sort data in tables (without pagination)
 * @param url with column, order and data
 * @returns sorted data
 */
export const sortData = (url: Temp) => {
  let order = url.order;
  let column = url.column;

  const srcData = url.data;

  let data: any[] = srcData;

  if (order !== '') {
    let sortCol = column;

    if (order === 'asc') {
      data = srcData.sort((a, b) => (a[sortCol] < b[sortCol] ? -1 : 1));
    } else {
      data = srcData.sort((a, b) => (a[sortCol] < b[sortCol] ? 1 : -1));
    }
  }

  return data;
};
