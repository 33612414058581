import React from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import clsx from 'clsx';
import useStyles from './responsiveDrawerStyles';

export const drawerWidth = 240;

export type ResponsiveDrawerProps = Omit<DrawerProps, 'variant'>;

function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const { className, classes: drawerClasses, ModalProps, ...drawerProps } = props;
  const classes = useStyles();

  const drawerClassesMemo = React.useMemo(() => {
    return {
      ...drawerClasses,
      paper: clsx(classes.drawerPaper, drawerClasses?.paper)
    };
  }, [classes.drawerPaper, drawerClasses]);

  const drawerClassNameMemo = React.useMemo(() => {
    return clsx(classes.root, className);
  }, [className, classes.root]);

  return (
    <>
      <Drawer
        sx={{ display: { xl: 'block', xs: 'none' } }}
        className={drawerClassNameMemo}
        classes={drawerClassesMemo}
        variant='persistent'
        {...drawerProps}
      />
      <Drawer
        sx={{ display: { xl: 'none', xs: 'block' } }}
        className={drawerClassNameMemo}
        classes={drawerClassesMemo}
        variant='temporary'
        {...drawerProps}
        {...ModalProps}
      />
    </>
  );
}

export default ResponsiveDrawer;
