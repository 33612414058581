import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EncryptionKeyContainer from './EncryptionKeyContainer';
import GraphMifToSend from './GraphMifToSend';
import GraphPoundRemoval from './GraphPoundRemoval';
import GraphPhoto from './GraphPhoto';
import GraphMifSent from './GraphMifSent';
import FvvContainer from './FvvContainer';
import CollectInfosContainer from './CollectInfosContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 10
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
    fontSize: '2rem',
    marginBottom: 10
  },
  background: {
    backgroundColor: 'white'
  },
  card: {
    padding: 16,
    marginBottom: 24
  },
  box: {
    display: 'flex'
  }
}));

type StatisticsProps = RouteComponentProps;

const Statistics = (props: StatisticsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.container}>
          <Typography className={classes.title} component='h1'>
            <Trans>Synthèse</Trans>
          </Typography>
          <div>
            <Box className={classes.box}>
              <EncryptionKeyContainer />
              <GraphPhoto />
            </Box>
            <GraphMifToSend />
            <GraphMifSent />
            <Box className={classes.box}>
              <GraphPoundRemoval />
              <CollectInfosContainer />
            </Box>
            <FvvContainer />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Statistics;
