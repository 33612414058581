import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Trans, t } from '@lingui/macro';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchKpiMifSent } from 'services/statisticsServices';
import { getDataGraphDateAndSentAndError } from 'utils/formatDataForGraph';
import useStyles from './styles';

const GraphMifSent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [mifsSent, setMifsSent] = useState<{ date: string; sent: number; error: number }[] | undefined>();

  const defaultPreviousDate: Date = new Date();
  defaultPreviousDate.setDate(defaultPreviousDate.getDate() - 15);
  const defaultDateNow: Date = new Date();

  useEffect(
    () =>
      void fetchKpiMifSent(defaultPreviousDate, defaultDateNow).then((res) => {
        if (res) {
          setMifsSent(getDataGraphDateAndSentAndError(res, defaultPreviousDate, defaultDateNow));
          setIsLoading(false);
        } else {
          setMifsSent(undefined);
        }
      }),
    []
  );

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (mifsSent === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    return (
      <ResponsiveContainer width='100%' minHeight={400}>
        <LineChart data={mifsSent} margin={{ top: 0, right: 40, left: -20, bottom: 0 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' tick={{ fontSize: 12 }} angle={-30} interval={0} dy={12} height={50} />
          <YAxis allowDecimals={false} tick={{ fontSize: 15 }} />
          <Tooltip />
          <Legend verticalAlign='top' align='right' />
          <Line type='monotone' dataKey='sent' name={t`MIFs envoyés`} stroke='#9966FF' activeDot={{ r: 8 }} />
          <Line type='monotone' dataKey='error' name={t`MIFs en erreur`} stroke='#FF9F40' activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Card className={classes.cardMifSent}>
      <Typography className={classes.title} component='h1'>
        <Trans>Nombre de MIFs envoyés par jour</Trans>
      </Typography>
      {component()}
    </Card>
  );
};
export default GraphMifSent;
