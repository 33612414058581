import { defineMessage } from '@lingui/macro';

export enum RoutePath {
  HOME = '/',
  LOGIN = '/login',
  ACCOUNT = '/account',
  EXPLOITATION = '/exploitation',
  MIFS_STATE = '/exploitation/mifs-state',
  MIFS_TO_SEND = '/exploitation/mifs-state/mifs-to-send',
  MIFS_IN_ERROR = '/exploitation/mifs-state/mifs-in-error',
  MIFS_SENT = '/exploitation/mifs-state/mifs-sent',
  MIFS_SENDING_HISTORY = '/exploitation/mifs-state/mifs-sending-history',
  COMMANDS = '/exploitation/commands',
  SEND_MIFS = '/exploitation/commands/send-mifs',
  VERIFY_MIFS_SENDING = '/exploitation/commands/verify-mifs-sending',
  RESEND_MIFS = '/exploitation/commands/resend-mifs',
  SEND_MIFS_FULL_PROCESS = '/exploitation/commands/send-mifs-full-process',
  UPDATE_ENCRYPTION_KEY = '/exploitation/commands/update-encryption-key',
  RECOVER_FVV = '/exploitation/commands/recover-fvv',
  CONNECT_CNT = '/exploitation/commands/connect-cnt',
  RECOVER_MIFS_MANUALLY = '/exploitation/commands/recover-mifs-manually',
  STATISTICS = '/statistics',
  MANAGEMENT = '/management',
  EQUIPMENTS = '/management/equipments',
  AGENTS = '/management/agents',
  BYLAWS = '/management/decree',
  INSTRUCTIONS = '/management/instructions',
  RADARS = '/management/radars',
  PHOTOS = '/photos'
}

export const i18nRouteLabel = {
  home: defineMessage({ message: 'Accueil' }),
  login: defineMessage({ message: 'Authification' }),
  account: defineMessage({ message: 'Compte' }),
  about: defineMessage({ message: 'A propos' }),
  exploitation: defineMessage({ message: 'Exploitation' }),
  mifsState: defineMessage({ message: 'État des MIFs' }),
  mifsToSend: defineMessage({ message: 'MIFs à envoyer' }),
  mifsInError: defineMessage({ message: 'MIFs en erreur' }),
  mifsSent: defineMessage({ message: 'MIFs envoyés' }),
  mifsSendingHistory: defineMessage({ message: "Historique d'envoi MIFs" }),
  commands: defineMessage({ message: 'Commandes' }),
  sendMifs: defineMessage({ message: 'Envoi de MIfs' }),
  verifyMifsSending: defineMessage({ message: "Verifier MIFs en cours d'envoi" }),
  resendMifs: defineMessage({ message: 'Renvoi de MIFs' }),
  sendMifsFullProcess: defineMessage({ message: 'Envoi de MIFs (procédure complète)' }),
  updateEncryptionKey: defineMessage({ message: 'Mise à jour clef de chiffrement' }),
  recoverFvv: defineMessage({ message: 'Récupérer FVV' }),
  connectCnt: defineMessage({ message: 'Connexion CNT' }),
  recoverMifsManually: defineMessage({ message: 'Récupérer MIFs (Manuellement)' }),
  statistics: defineMessage({ message: 'Statistique' }),
  management: defineMessage({ message: 'Configuration' }),
  equipments: defineMessage({ message: 'Équipements' }),
  agents: defineMessage({ message: 'Agents' }),
  decree: defineMessage({ message: 'Arrêtés' }),
  instructions: defineMessage({ message: 'Consignes' }),
  radars: defineMessage({ message: 'Radars' }),
  photos: defineMessage({ message: 'Photos' })
};
