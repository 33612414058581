import React from 'react';
import Container from '@mui/material/Container';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import FormEquipments from './FormEquipments';
import { StyledDataGrid } from '../../components/DataGrid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Trans, t } from '@lingui/macro';
import { TableCell, TableRow } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import {
  fetchEquipment,
  updateEquipment,
  createEquipment,
  deleteEquipment,
  CreateEquipmentPayload,
  Equipment,
  Filters,
  UpdateEquipmentPayload
} from 'services/equipmentServices';
import useStyles from './styles';
import useCommonStyles from 'commonStyles';
import TextField from 'components/TextField/TextField';
import { MenuItem } from '@mui/material';
import { noSelectionLabel, noSelectionValue } from 'constants/constants';
import { corpsOptions, functionNameOptions, siteOptions, stateOptions, stateCodeMapper, siteCodeMapper } from './constants';
import { sortData, Temp } from 'utils/sortTable';

function Equipments() {
  const [equipments, setEquipments] = React.useState<Equipment[]>([]);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<Equipment>();
  const [count, setCount] = React.useState<number>(0);

  const selectedId = React.useRef<undefined | number>(undefined);
  const filters = React.useRef<Filters>({});

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const openDialogEdit = (data: Equipment) => () => {
    setSelectedData(data);
    setOpenEditDialog(true);
  };

  const openDialogDelete = (id: number) => () => {
    selectedId.current = id;
    setOpenDelete(true);
  };

  const closeDialogDelete = () => {
    setOpenDelete(false);
  };

  const deleteItem = async () => {
    setOpenDelete(false);
    const deletedEquipmentServiceResponse = await deleteEquipment(selectedId.current as number);

    const updatedEquipmentsState = equipments.filter((equipment) => equipment.id !== deletedEquipmentServiceResponse.id);

    setEquipments(updatedEquipmentsState);
  };

  const updateEquipmentHandler = async (updateEquipmentPayload: UpdateEquipmentPayload, id: number) => {
    const updateEquipmentServiceResponse = await updateEquipment(updateEquipmentPayload, id);

    const updatedEquipmentState = equipments.map((equipment) => {
      if (equipment.id === updateEquipmentServiceResponse.id) {
        return updateEquipmentServiceResponse;
      }

      return equipment;
    });

    setEquipments(updatedEquipmentState);
  };

  const createEquipmentHandler = async (createEquipmentPayload: CreateEquipmentPayload) => {
    const createdEquipmentServiceResponse: Equipment = await createEquipment(createEquipmentPayload);

    setEquipments([...equipments, createdEquipmentServiceResponse]);
  };

  const fetchEquipmentsWithFilters = async () => {
    const equipmentServiceResponse = await fetchEquipment(filters.current);

    setEquipments(equipmentServiceResponse);
  };

  const resetFilters = async () => {
    filters.current = {};
    const equipmentServiceResponse = await fetchEquipment();

    setEquipments(equipmentServiceResponse);
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue = filterFromDataTable[changedColumnIndex][0] ? filterFromDataTable[changedColumnIndex][0] : undefined;

    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is = undefined || = "all"
    if (filterFromDataTable[changedColumnIndex][0] === undefined || filterFromDataTable[changedColumnIndex][0] === 'all') {
      delete filters.current[columnName as keyof Filters];
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'antai_id',
      label: t`ID ANTAI`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`ID ANTAI`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'serial_number',
      label: t`Num série`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Num série`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'mac_adress',
      label: t`Adresse MAC`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Adresse MAC`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'corps',
      label: t`Corps`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='corps'
                label={t`Corps`}
                select
                fullWidth
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {corpsOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        }
      }
    },
    {
      name: 'function',
      label: t`Fonction`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='function'
                label={t`Fonction`}
                select
                fullWidth
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {functionNameOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        }
      }
    },
    {
      name: 'service_code',
      label: t`Code Service`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Code Service`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'brand',
      label: t`Marque`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Marque`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'model',
      label: t`Modèle`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Modèle`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'state',
      label: t`État`,
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => stateCodeMapper[value]
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='state'
                label={t`État`}
                select
                fullWidth
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {stateOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{t`${stateCodeMapper[equipments[rowIndex].state]}`}</span>
      }
    },
    {
      name: 'site',
      label: t`Site`,
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => siteCodeMapper[value]
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='site'
                label={t`Site`}
                select
                fullWidth
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {siteOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <span>{equipments[rowIndex].site ? t`${siteCodeMapper[equipments[rowIndex].site as number]}` : ''}</span>
        )
      }
    },
    {
      name: 'sim_sn',
      label: t`Carte SIM`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Carte SIM`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'battery_sn',
      label: t`Numéro batterie`,
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Numéro batterie`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: 80 }
        }),
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
          return (
            <>
              <Tooltip title={'Modifier cet équipement'}>
                <IconButton
                  className={classes.customButtonContent}
                  onClick={openDialogEdit(equipments[dataIndex])}
                  size='large'
                  data-testid={`updateEquipment${rowIndex}`}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Supprimer cet équipement'}>
                <IconButton
                  className={classes.customButtonContent}
                  onClick={openDialogDelete(equipments[dataIndex].id)}
                  size='large'
                  data-testid={`deleteEquipment${rowIndex}`}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  React.useEffect(() => {
    async function fetchEquipmentAsync() {
      const equipmentServicesResponse: Equipment[] = await fetchEquipment();

      setEquipments(equipmentServicesResponse);
      setCount(equipmentServicesResponse.length);
    }

    fetchEquipmentAsync();
  }, []);

  const sort = (column: string, order: string) => {
    let temp: Temp = {
      column: column,
      order: order,
      data: equipments
    };

    setEquipments([...sortData(temp)]);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <StyledDataGrid
            title={'Gestion des équipements'}
            data={equipments}
            columns={columns}
            options={{
              serverSide: true,
              count: count,
              pagination: false,
              tableBodyHeight: 'auto',
              tableBodyMaxHeight: '600px',
              onColumnSortChange: (changedColumn, direction) => {
                sort(changedColumn, direction);
              },
              onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
              },
              onFilterChipClose(index, removedFilter, filterList) {
                buildFitlers(removedFilter as string | null, filterList, index);
                fetchEquipmentsWithFilters();
              },
              customToolbar: () => (
                <Tooltip title={'Ajouter un équipement'}>
                  <IconButton
                    className={classes.customButtonHeader}
                    onClick={() => setOpenCreateDialog(true)}
                    size='large'
                    data-testid='createEquipment'
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ),
              customFilterDialogFooter: () => {
                return (
                  <div className={commonClasses.customFilterDialogFooter}>
                    <Button variant='text' onClick={() => resetFilters()}>
                      <Trans>Tout effacer</Trans>
                    </Button>
                    <Button
                      className={commonClasses.customFilterDialogFooterApplyButotn}
                      variant='contained'
                      onClick={() => fetchEquipmentsWithFilters()}
                    >
                      <Trans>Appliquer</Trans>
                    </Button>
                  </div>
                );
              },
              customFooter: (count) => {
                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell className={classes.customFooter}>
                        <Trans>Total : {count}</Trans>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              }
            }}
            components={{
              TableHead: (props) => {
                return <TableHead {...props} />;
              },
              TableToolbar: (props) => {
                return <TableToolbar {...props} />;
              }
            }}
          />
        </div>
      </Container>
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <FormEquipments
          actionType='create'
          formTitle={t`Ajouter un équipement`}
          submitHandler={(equipment) => createEquipmentHandler(equipment as CreateEquipmentPayload)}
          closeModalHandler={() => setOpenCreateDialog(false)}
        />
      </Dialog>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <FormEquipments
          actionType='update'
          formTitle={t`Modifier un équipement`}
          submitHandler={(equipment, id) => updateEquipmentHandler(equipment as UpdateEquipmentPayload, id as number)}
          closeModalHandler={() => setOpenEditDialog(false)}
          data={selectedData}
        />
      </Dialog>

      <Dialog open={openDelete} onClose={closeDialogDelete}>
        <DialogTitle className={classes.dialogHeader} id='form-dialog-title'>
          Supprimer
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>Etes-vous sur de vous supprimer l'équipement ?</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogDelete} color='primary' variant='contained'>
            <Trans>Non</Trans>
          </Button>
          <Button onClick={deleteItem} color='primary' style={{ marginLeft: 8 }} variant='contained' data-testid='confirmDeleteEquipment'>
            <Trans>Oui</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Equipments;
