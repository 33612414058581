import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Trans, t } from '@lingui/macro';
import frLocale from 'date-fns/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from 'components/TextField/TextField';
import { localeDateToISO8601, toLocaleDateString } from 'utils/dateFormatter';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import { MifInError, fetchMifInError, Filter, Response } from 'services/mifsInErrorServices';
import { StyledDataGrid } from '../../components/DataGrid';
import { SortOrder, Pagination } from 'utils/sortTable';
import useCommonStyles from 'commonStyles';
import useStyles from './styles';

type MifsInErrorProps = RouteComponentProps;

function MifsInError(props: MifsInErrorProps) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [mifsInError, setMifsInError] = useState<MifInError[]>([]);
  const [count, setCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>({ name: '', direction: '' });
  const [pagination, setPagination] = useState<Pagination>({ page: 0, rowsPerPage: 10 });
  const filters = useRef<Filter>({});

  const fetchMifsInErrorAsync = async (withFilters: boolean = false) => {
    const mifsInErrorServicesResponse: Response | undefined = await fetchMifInError(
      pagination,
      sortOrder,
      withFilters ? filters.current : undefined
    );
    if (mifsInErrorServicesResponse) {
      setMifsInError(mifsInErrorServicesResponse.datas);
      setCount(mifsInErrorServicesResponse.total);
    }
  };

  useEffect(() => {
    fetchMifsInErrorAsync();
  }, [pagination, sortOrder]);

  const fetchMifsInErrorWithFilters = async () => {
    fetchMifsInErrorAsync(true);
  };

  const resetFilters = async () => {
    filters.current = {};
    fetchMifsInErrorAsync();
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue = filterFromDataTable[changedColumnIndex][0] ? filterFromDataTable[changedColumnIndex][0] : undefined;

    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is = undefined || = "all"
    if (filterFromDataTable[changedColumnIndex][0] === undefined || filterFromDataTable[changedColumnIndex][0] === 'all') {
      delete filters.current[columnName as keyof Filter];
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'equipment',
      label: t`Code équipement`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Code équipement: ${v}` },
        filterType: 'textField'
      }
    },
    {
      name: 'matricule',
      label: t`Code agent`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Code agent: ${v}` },
        filterType: 'textField'
      }
    },
    {
      name: 'date',
      label: t`Date tentative`,
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: { render: (v) => t`Date tentative: ${v}` },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Date tentative`}
                inputFormat={'dd/MM/yyyy'}
                value={filterList[index][0] || ''}
                onChange={(event: Date | null) => {
                  filterList[index] = [localeDateToISO8601(event)];
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => {
                  params.error = false;
                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{toLocaleDateString(mifsInError[rowIndex].date)}</span>
      }
    },
    {
      name: 'file_name',
      label: t`Nom du fichier d'erreur`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Nom du fichier d'erreur: ${v}` },
        filterType: 'textField'
      }
    },
    {
      name: 'error',
      label: t`Type d'erreur`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Type d'erreur: ${v}` },
        filterType: 'textField'
      }
    }
  ];

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <StyledDataGrid
            title={t`Liste des MIFs en erreur`}
            data={mifsInError}
            columns={columns}
            options={{
              filter: true,
              filterType: 'dropdown',
              responsive: 'standard',
              serverSide: true,
              count: count,
              page: pagination.page,
              rowsPerPage: pagination.rowsPerPage,
              tableBodyHeight: 'auto',
              tableBodyMaxHeight: '600px',
              onColumnSortChange: (changedColumn, direction) => {
                setSortOrder({ name: changedColumn, direction: direction });
              },
              onChangePage: (page) => {
                setPagination({ page: page, rowsPerPage: pagination.rowsPerPage });
              },
              onChangeRowsPerPage(numberOfRows) {
                setPagination({ page: pagination.page, rowsPerPage: numberOfRows });
              },
              onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
              },
              onFilterChipClose(index, removedFilter, filterList) {
                buildFitlers(removedFilter as string | null, filterList, index);
                fetchMifsInErrorWithFilters();
              },
              customFilterDialogFooter: () => {
                return (
                  <div className={commonClasses.customFilterDialogFooter}>
                    <Button variant='text' onClick={() => resetFilters()}>
                      <Trans>Tout effacer</Trans>
                    </Button>
                    <Button
                      className={commonClasses.customFilterDialogFooterApplyButotn}
                      variant='contained'
                      onClick={() => fetchMifsInErrorWithFilters()}
                    >
                      <Trans>Appliquer</Trans>
                    </Button>
                  </div>
                );
              }
            }}
            components={{
              TableHead: (props) => {
                return <TableHead {...props} />;
              },
              TableToolbar: (props) => {
                return <TableToolbar {...props} />;
              }
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default MifsInError;
