import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Trans } from '@lingui/macro';
import { fetchKpiCollectInfos } from 'services/statisticsServices';
import { CollectInfos } from 'services/statisticsServices';
import useStyles from './styles';

function CollectInfosContainer() {
  const classes = useStyles();
  const [collectInfos, setCollectInfos] = useState<CollectInfos[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () =>
      void fetchKpiCollectInfos().then((res) => {
        setCollectInfos(res);
        setIsLoading(false);
      }),
    []
  );

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (collectInfos === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    return (
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableBody>
            <TableRow key='agents'>
              <TableCell component='th' scope='row'>
                <Trans>Agents actifs</Trans>
              </TableCell>
              <TableCell align='right'>{collectInfos[0].agents}</TableCell>
            </TableRow>
            <TableRow key='equipments'>
              <TableCell component='th' scope='row'>
                <Trans>Équipements actifs</Trans>
              </TableCell>
              <TableCell align='right'>{collectInfos[0].equipments}</TableCell>
            </TableRow>
            <TableRow key='instructions'>
              <TableCell component='th' scope='row'>
                <Trans>Consignes</Trans>
              </TableCell>
              <TableCell align='right'>{collectInfos[0].instructions}</TableCell>
            </TableRow>
            <TableRow key='decrees'>
              <TableCell component='th' scope='row'>
                <Trans>Arrêtés</Trans>
              </TableCell>
              <TableCell align='right'>{collectInfos[0].decrees}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Card className={classes.cardCollectInfos}>
      <Typography className={classes.title} component='h1'>
        <Trans>Informations</Trans>
      </Typography>
      {component()}
    </Card>
  );
}

export default CollectInfosContainer;
