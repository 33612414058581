import { i18nRouteLabel } from 'routes/routes';
import { RoutePath } from 'routes/routes';

const routePathAndLabel = [
  {
    path: RoutePath.HOME,
    label: i18nRouteLabel.home
  },
  {
    path: RoutePath.LOGIN,
    label: i18nRouteLabel.login
  },
  {
    path: RoutePath.ACCOUNT,
    label: i18nRouteLabel.account
  },
  {
    path: RoutePath.EXPLOITATION,
    label: i18nRouteLabel.exploitation
  },
  {
    path: RoutePath.MIFS_STATE,
    label: i18nRouteLabel.mifsState
  },
  {
    path: RoutePath.MIFS_TO_SEND,
    label: i18nRouteLabel.mifsToSend
  },
  {
    path: RoutePath.MIFS_IN_ERROR,
    label: i18nRouteLabel.mifsInError
  },
  {
    path: RoutePath.MIFS_SENT,
    label: i18nRouteLabel.mifsSent
  },
  {
    path: RoutePath.MIFS_SENDING_HISTORY,
    label: i18nRouteLabel.mifsSendingHistory
  },
  {
    path: RoutePath.COMMANDS,
    label: i18nRouteLabel.commands
  },
  {
    path: RoutePath.SEND_MIFS,
    label: i18nRouteLabel.sendMifs
  },
  {
    path: RoutePath.VERIFY_MIFS_SENDING,
    label: i18nRouteLabel.verifyMifsSending
  },
  {
    path: RoutePath.RESEND_MIFS,
    label: i18nRouteLabel.resendMifs
  },
  {
    path: RoutePath.SEND_MIFS_FULL_PROCESS,
    label: i18nRouteLabel.sendMifsFullProcess
  },
  {
    path: RoutePath.UPDATE_ENCRYPTION_KEY,
    label: i18nRouteLabel.updateEncryptionKey
  },
  {
    path: RoutePath.RECOVER_FVV,
    label: i18nRouteLabel.recoverFvv
  },
  {
    path: RoutePath.CONNECT_CNT,
    label: i18nRouteLabel.connectCnt
  },
  {
    path: RoutePath.RECOVER_MIFS_MANUALLY,
    label: i18nRouteLabel.recoverMifsManually
  },
  {
    path: RoutePath.STATISTICS,
    label: i18nRouteLabel.statistics
  },
  {
    path: RoutePath.MANAGEMENT,
    label: i18nRouteLabel.management
  },
  {
    path: RoutePath.EQUIPMENTS,
    label: i18nRouteLabel.equipments
  },
  {
    path: RoutePath.AGENTS,
    label: i18nRouteLabel.agents
  },
  {
    path: RoutePath.BYLAWS,
    label: i18nRouteLabel.decree
  },
  {
    path: RoutePath.INSTRUCTIONS,
    label: i18nRouteLabel.instructions
  },
  {
    path: RoutePath.RADARS,
    label: i18nRouteLabel.radars
  },
  {
    path: RoutePath.PHOTOS,
    label: i18nRouteLabel.photos
  }
];

export default routePathAndLabel;
