import Axios from './Axios';
import { store } from 'store';

export interface Agent {
  id: number;
  agent_id: string;
  name: string;
  surname: string;
  service_code: string;
  function: string;
  enabled: boolean;
  card_serial: string;
  equipment_serial: string;
  created_at: string;
  updated_at: string;
}

export type CreateAgentPayload = {
  agent_id: string;
  name: string;
  surname: string;
  function: string;
  enabled: boolean;
  service_code?: string;
  card_serial?: string;
  equipment_serial?: string;
};

export type UpdateAgentPayload = Omit<Agent, 'id' | 'created_at' | 'updated_at'>;

export type Filter = Omit<Partial<Agent>, 'id'>;

export const fetchAgent = async (filters: Filter = {}): Promise<Agent[]> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Agent[]>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/agent',
    params: filters,
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const fetchAgentById = async (id: string): Promise<Agent> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Agent>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/agent/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const createAgent = async (craeteagentPayload: CreateAgentPayload): Promise<Agent> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Agent>({
    method: 'post',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/agent',
    data: craeteagentPayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const updateAgent = async (updateagentPayload: UpdateAgentPayload, id: number): Promise<Agent> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Agent>({
    method: 'put',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/agent/${id}`,
    data: updateagentPayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const deleteAgent = async (id: number): Promise<Agent> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Agent>({
    method: 'delete',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/agent/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};
