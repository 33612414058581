import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  dataTableContainer: {
    marginBottom: 16
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
    fontSize: '1.5rem'
  },
  h2: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bolder',
    color: theme.palette.secondary.main,
    fontSize: '1.2rem'
  },
  historyHeader: {
    fontWeight: 'bolder',
    fontSize: '0.9rem',
    color: '#fff',
    background: theme.palette.primary.main,
    padding: 8,
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  historyBloc: {
    padding: '8px 0'
  }
}));

export default useStyles;
