export const transformFormValueStringToArray = (formValue: string): string[] => {
  const regex = /[,]/g; //find ','
  const matches = formValue.match(regex);

  return matches ? formValue.split(',') : [formValue];
};

export const transformFormValueArrayToString = (formValue: string[] | null | undefined) => {
  return formValue && formValue.length > 1 ? formValue.join(',') : formValue && formValue.length === 1 ? formValue[0] : '';
};
