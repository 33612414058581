import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Trans, t } from '@lingui/macro';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchKpiPhoto, Photo } from 'services/statisticsServices';
import { formatDataGraphDateAndNum } from 'utils/formatDataForGraph';
import useStyles from './styles';

function GraphPhoto() {
  const classes = useStyles();
  const [photos, setPhotos] = useState<Photo[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const defaultPreviousDate: Date = new Date();
  defaultPreviousDate.setDate(defaultPreviousDate.getDate() - 15);
  const defaultDateNow: Date = new Date();

  useEffect(
    () =>
      void fetchKpiPhoto(defaultPreviousDate, defaultDateNow).then((res) => {
        if (res) {
          setPhotos(formatDataGraphDateAndNum(res, defaultPreviousDate, defaultDateNow));
          setIsLoading(false);
        } else {
          setPhotos(undefined);
        }
      }),
    []
  );

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (photos === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    return (
      <ResponsiveContainer width='100%' height={300}>
        <LineChart data={photos} margin={{ top: 0, right: 40, left: -20, bottom: 0 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' tick={{ fontSize: 12 }} angle={-30} interval={0} dy={12} height={50} />
          <YAxis allowDecimals={false} tick={{ fontSize: 15 }} />
          <Tooltip />
          <Legend verticalAlign='top' align='right' />
          <Line type='monotone' dataKey='num' name={t`Total`} stroke='#9966FF' />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Card className={classes.card}>
      <Typography className={classes.title} component='h1'>
        <Trans>Nombre de photos par jour</Trans>
      </Typography>
      {component()}
    </Card>
  );
}

export default GraphPhoto;
