import { store } from 'store';
import Axios from './Axios';
import { SortOrder, Pagination } from 'utils/sortTable';

export interface MifSendingHistory {
  id: number;
  ckmif_file_name: string;
  file_name: string;
  state: string;
  action: string;
  reemit_number: number;
  date: Date;
}

export interface Response {
  datas: MifSendingHistory[];
  total: number;
}

export interface FileName {
  ckmif_file_name: string;
  date: Date;
}

export type Filter = Omit<Partial<MifSendingHistory>, 'id'>;

export const fetchMifSendingHistory = async (pagination: Pagination, sort: SortOrder, file_name: string): Promise<Response | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Response>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/mif-sending-history',
    params: {
      pagination,
      sort,
      file_name
    },
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchFileNameMifSendingHistory = async (): Promise<FileName[] | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<FileName[]>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/mif-sending-history',
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};
