import React from 'react';
import MuiDataTable, { MUIDataTableProps as MuiDataTableProps } from 'mui-datatables';

export type DataGridProps = MuiDataTableProps;

function DataGrid(props: DataGridProps) {
  const { ...other } = props;

  return <MuiDataTable {...other} />;
}

export default DataGrid;
