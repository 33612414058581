import React from 'react';
import frLocale from 'date-fns/locale/fr';
import MenuItem from '@mui/material/MenuItem';
import TextField from 'components/TextField/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Typography from '@mui/material/Typography';
import { t, Trans } from '@lingui/macro';
import { CreateDecreePayload, Decree, UpdateDecreePayload } from 'services/decreeServices';
import { localeDateToISO8601 } from 'utils/dateFormatter';
import { transformFormValueArrayToString, transformFormValueStringToArray } from 'utils/formDataUtils';
import { textFieldIsEmpty } from 'validator/validator';
import useStyles from './styleFormDecrees';
import * as constants from './constants';
import { emptyTextFieldErrorMessage } from 'constants/constants';

export type FormDecreeProps = {
  formTitle: string;
  closeModalHandler: () => void;
  actionType: 'create' | 'update';
  submitHandler?: (decreePayload: CreateDecreePayload | UpdateDecreePayload, id?: number) => Promise<void>;
  data?: Decree;
};

export type FormDecree = Omit<Decree, 'id' | 'created_at' | 'updated_at'>;

const defaultState: FormDecree = {
  type: 1,
  decree_id: '',
  date: new Date().toISOString(),
  description: '',
  natinf: []
};

function FormDecree(props: FormDecreeProps) {
  const [decreeData, setDecreeData] = React.useState<FormDecree>(defaultState);
  const [typeEmpty, setTypeEmpty] = React.useState<boolean>(false);
  const [decreeIdEmpty, setDecreeIdEmpty] = React.useState<boolean>(false);
  const [descriptionEmpty, setDescriptionEmpty] = React.useState<boolean>(false);
  const [dateEmpty, setDateEmpty] = React.useState<boolean>(false);
  const [natinfEmpty, setNatingEmpty] = React.useState<boolean>(false);

  const { formTitle, closeModalHandler, data, actionType, submitHandler } = props;

  const formDecreeInError = React.useRef(false);

  const classes = useStyles();

  const closeModal = () => {
    setDecreeData(defaultState);
    closeModalHandler();
  };

  const validateRequiredTextField = () => {
    let isValid = true;

    if (textFieldIsEmpty(decreeData.type) === true) {
      isValid = false;
      setTypeEmpty(true);
    }
    if (textFieldIsEmpty(decreeData.decree_id) === true) {
      isValid = false;
      setDecreeIdEmpty(true);
    }
    if (textFieldIsEmpty(decreeData.description) === true) {
      isValid = false;
      setDescriptionEmpty(true);
    }
    if (textFieldIsEmpty(decreeData.date) === true) {
      isValid = false;
      setDateEmpty(true);
    }
    if (decreeData.natinf.length === 0) {
      isValid = false;
      setNatingEmpty(true);
    }

    return isValid;
  };

  const handleChange = (fieldName: string, fieldValue: string | string[] | number | Date) => {
    setDecreeData((prev) => ({
      ...prev,
      [fieldName]: fieldValue
    }));
  };

  const formatDecreeData = (decreeData: FormDecree): CreateDecreePayload | UpdateDecreePayload => {
    let formattedDecreeData = {
      type: decreeData.type,
      decree_id: decreeData.decree_id,
      date: decreeData.date,
      description: decreeData.description,
      natinf: decreeData.natinf
    };

    return formattedDecreeData;
  };

  const submit = () => {
    if (formDecreeInError.current === true) {
      return;
    }
    const validation = validateRequiredTextField();
    if (validation === false) {
      return;
    }

    const formattedDecreeData = formatDecreeData(decreeData);

    submitHandler && submitHandler(formattedDecreeData, data && data.id);
    closeModal();
  };

  React.useEffect(() => {
    actionType === 'create' && setDecreeData(defaultState);
    actionType === 'update' &&
      data &&
      setDecreeData({
        type: data.type,
        decree_id: data.decree_id,
        date: data.date,
        description: data.description,
        natinf: data.natinf
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography component='h2' variant='h6'>
          {formTitle}
        </Typography>
      </div>
      <div className={classes.body}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              variant='outlined'
              id='type'
              label="Type d'arrêtés"
              fullWidth
              size='small'
              select
              error={typeEmpty}
              helperText={typeEmpty ? emptyTextFieldErrorMessage : ''}
              value={decreeData.type}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('type', event.target.value);
              }}
            >
              {constants.decreeTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              id='decree_id'
              label='Référence*'
              fullWidth
              size='small'
              variant='outlined'
              error={decreeIdEmpty}
              helperText={decreeIdEmpty ? emptyTextFieldErrorMessage : ''}
              //lengthRestriction={50}
              value={decreeData.decree_id}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('decree_id', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Date mise en service*`}
                inputFormat={'dd/MM/yyyy'}
                value={decreeData.date}
                onChange={(event: Date | null) => {
                  event && handleChange('date', localeDateToISO8601(event));
                }}
                renderInput={(params) => {
                  params.error = dateEmpty;
                  params.helperText = dateEmpty ? emptyTextFieldErrorMessage : '';

                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              variant='outlined'
              id='natinf'
              label='NatInf*'
              fullWidth
              size='small'
              error={natinfEmpty}
              helperText={
                natinfEmpty ? emptyTextFieldErrorMessage : <Trans>Si multiple NatInf, séparés par virgules. Ex : "007,567"</Trans>
              }
              value={transformFormValueArrayToString(decreeData.natinf)}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                const value = transformFormValueStringToArray(e.target.value);
                handleChange('natinf', value);
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              variant='outlined'
              id='description'
              label='Description*'
              fullWidth
              size='small'
              multiline
              rows={4}
              error={descriptionEmpty}
              helperText={descriptionEmpty ? emptyTextFieldErrorMessage : ''}
              value={decreeData.description}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('description', event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <Button variant='contained' onClick={closeModal} color='primary'>
          <Trans>Annuler</Trans>
        </Button>
        <Button data-testid='modalUpdateCreateConfirmButton' style={{ marginLeft: 8 }} variant='contained' color='primary' onClick={submit}>
          <Trans>Valider</Trans>
        </Button>
      </div>
    </div>
  );
}

export default FormDecree;
