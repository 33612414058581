import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  header: {
    flex: '0 0 auto',
    margin: 0,
    padding: '16px 24px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  body: {
    flex: '1 1 auto',
    padding: '24px 24px 8px 24px',
    overflowY: 'auto'
  },
  footer: {
    flex: '0 0 auto',
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default useStyles;
