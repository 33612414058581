import Keycloak from 'keycloak-js';
import { setCityId, setLoggedIn, setLogoutUrl, setLoginUrl, setRoles, setToken, setUserAccountUrl, setLoading } from 'reducers/authSlice';
import { store } from 'store';

type TokenParsed = Keycloak.KeycloakTokenParsed & {
  jti?: string;
  iss?: string;
  aud?: string[];
  typ?: string;
  azp?: string;
  acr?: string;
  'allowed-origins'?: string[];
  scope?: string;
  sid?: string;
  email_verified: boolean;
  preferred_username: string[];
  city_id?: string;
};

const initKeyClaok = () => {
  const KeycloakInstance: Keycloak.KeycloakInstance = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL || 'http://url:port/auth',
    realm: process.env.REACT_APP_KEYCLOAK_REALM || 'realm_name',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || 'clientId_name'
  });

  //KeycloakInstance.init({ onLoad: (process.env.REACT_APP_KEYCLOAK_ONLOAD as Keycloak.KeycloakOnLoad) || 'login-required' });
  KeycloakInstance.init({ onLoad: 'login-required' });

  store.dispatch(setLogoutUrl(KeycloakInstance.createLogoutUrl()));

  store.dispatch(setLoginUrl(KeycloakInstance.createLoginUrl()));

  store.dispatch(setUserAccountUrl(KeycloakInstance.createAccountUrl()));

  KeycloakInstance.onReady = () => store.dispatch(setLoading(false));

  KeycloakInstance.onAuthSuccess = () => {
    store.dispatch(setToken(KeycloakInstance.token as string));
    const tokenParsed = KeycloakInstance.tokenParsed as TokenParsed | undefined;

    const roles: string[] =
      tokenParsed && tokenParsed.resource_access
        ? tokenParsed.resource_access[tokenParsed.azp as string].roles.map((roles: string) => roles)
        : [];
    tokenParsed && store.dispatch(setRoles(roles));
    tokenParsed && tokenParsed.city_id && store.dispatch(setCityId(tokenParsed.city_id));
    store.dispatch(setLoggedIn(true));
  };
};

export default initKeyClaok;
