import { configureStore } from '@reduxjs/toolkit';
import networkUsageSlice from 'reducers/networkUsageSlice';
import i18nSlice from 'reducers/i18nSlice';
import layoutSlice from 'reducers/layoutSlice';
import authSlice from 'reducers/authSlice';

export const store = configureStore({
  reducer: {
    networkUsage: networkUsageSlice,
    i18n: i18nSlice,
    layout: layoutSlice,
    auth: authSlice
  },
  devTools: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
