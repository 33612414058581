import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { drawerWidth } from './ResponsiveDrawer';
import background from 'assets/background.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  spacer: {
    flexGrow: 1
  },
  exyztLogoWhite: {
    height: 48,
    '@media (min-width:0px) and (orientation: landscape)': {
      height: 40
    },
    '@media (min-width:600px)': {
      height: 56
    }
  },
  exyztLogoWhiteBottom: {
    height: 32,
    margin: '0 8px',
    verticalAlign: 'middle'
  },
  logoTaeCollecte: {
    height: 32,
    '@media (min-width:0px) and (orientation: landscape)': {
      height: 24
    },
    '@media (min-width:600px)': {
      height: 32
    }
  },
  logoTaeCollecteFull: {
    height: 40,
    '@media (min-width:0px) and (orientation: landscape)': {
      height: 32
    },
    '@media (min-width:600px)': {
      height: 40
    }
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: theme.direction === 'ltr' ? 'flex-end' : 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main
  },
  content: {
    width: '100%',
    height: 'calc(100% - 96px)',
    padding: 16,
    backgroundColor: theme.palette.grey[300],
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: 'auto',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
  },
  contentWithFooter: {
    width: '100%',

    [theme.breakpoints.only('xs')]: {
      height: 'calc(100% - 48px)'
    },

    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 150px)'
    },

    backgroundColor: theme.palette.grey[300],
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: 'auto',
    padding: 16,
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    textAlign: 'center',
    padding: 8
  },
  topBarActionZone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 220
  },
  topBarActionZone2: {
    display: 'flex',
    alignItems: 'center'
  },
  chevron: {
    color: theme.palette.common.white
  },
  langSelector: {
    textAlign: 'center',
    padding: 2,
    background: theme.palette.grey[500],
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center'
  },
  cntOk: {
    textAlign: 'center',
    padding: 2,
    background: 'green',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center'
  },
  cntKo: {
    textAlign: 'center',
    padding: 2,
    background: '#cc0000',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center'
  },
  menuContainer: {
    position: 'relative',
    top: 0,
    left: 0
  },
  footerText: {
    fontSize: '85%'
  }
}));

export default useStyles;
