export const statusOptions = [
  {
    value: 1,
    label: 'Important'
  },
  {
    value: 2,
    label: 'Normal'
  },
  {
    value: 3,
    label: 'Archive'
  }
];

export enum StatusNameToCodeMapper {
  Important = 1,
  Normal = 2,
  Archive = 3
}

export const statusCodeToNameMapper: { [index: number]: string } = {
  1: 'Important',
  2: 'Normal',
  3: 'Archive'
};

export const functionNameOptions = [
  {
    value: 'ASVP',
    label: 'ASVP'
  },
  {
    value: 'APJ',
    label: 'APJ'
  },
  {
    value: 'APJA',
    label: 'APJA'
  },
  {
    value: 'OPJ',
    label: 'OPJ'
  }
];

export type FunctionName = 'ASVP' | 'APJ' | 'APJA' | 'OPJ';

export enum FunctionNameMapper {
  ASVP = 'ASVP',
  APJ = 'APJ',
  APJA = 'APJA',
  OPJ = 'OPJ'
}
