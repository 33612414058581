import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  }
}));

type RadarsProps = RouteComponentProps;

function Radars(props: RadarsProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>TODO</Container>
    </div>
  );
}

export default Radars;
