import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { defaultTheme } from 'themes';
import Router from 'routes/Router';
import Layout from 'components/Layout';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages } from './locales/fr/messages';
import { en, fr } from 'make-plural/plurals';
import initKeycloak from 'helpers/initKeycloak';
import { store } from './store';

i18n.load('fr', messages);
i18n.loadLocaleData('fr', { plurals: fr });
i18n.loadLocaleData('en', { plurals: en });
i18n.activate('fr');

function App() {
  React.useEffect(() => {
    initKeycloak();
  }, []);

  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultTheme}>
            <BrowserRouter>
              <I18nProvider i18n={i18n}>
                <Layout>
                  <Router />
                </Layout>
              </I18nProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </>
  );
}

export default App;
