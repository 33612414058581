import Axios from './Axios';
import { store } from 'store';

export interface Instruction {
  id: number;
  start_date: string;
  end_date: string;
  subject: string;
  description: string;
  function: string;
  agent_id: string[] | null;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface CreateInstructionPayload {
  start_date: string;
  end_date: string;
  subject: string;
  description: string;
  status: string;
  function?: string;
  agent_id?: string[];
}

export type UpdateInstructionPayload = Omit<Instruction, 'id' | 'created_at' | 'updated_at'>;

export type Filter = Omit<Partial<Instruction>, 'id'>;

export const fetchInstruction = async (filters: Filter = {}): Promise<Instruction[]> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Instruction[]>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/instruction',
    params: filters,
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const fetchInstructionById = async (id: string): Promise<Instruction> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Instruction>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/instruction/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const createInstruction = async (craeteinstructionPayload: CreateInstructionPayload): Promise<Instruction> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Instruction>({
    method: 'post',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/instruction',
    data: craeteinstructionPayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const updateInstruction = async (updateinstructionPayload: UpdateInstructionPayload, id: number): Promise<Instruction> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Instruction>({
    method: 'put',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/instruction/${id}`,
    data: updateinstructionPayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const deleteInstruction = async (id: number): Promise<Instruction> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Instruction>({
    method: 'delete',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/instruction/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};
