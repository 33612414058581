import React from 'react';
import frLocale from 'date-fns/locale/fr';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { t, Trans } from '@lingui/macro';
import TextField from 'components/TextField/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { CreateInstructionPayload, Instruction, UpdateInstructionPayload } from 'services/instructionServices';
import { textFieldIsEmpty } from 'validator/validator';
import * as constants from './constants';
import useStyles from './formInstructionStyles';

export type FormInstructionsProps = {
  formTitle: string;
  closeModalHandler: () => void;
  actionType: 'create' | 'update';
  submitHandler?: (instructionPayload: CreateInstructionPayload | UpdateInstructionPayload, id?: number) => Promise<void>;
  data?: Instruction;
};

export type FormInstruction = Omit<Instruction, 'id' | 'created_at' | 'updated_at'>;

const defaultState: FormInstruction = {
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  subject: '',
  description: '',
  function: constants.FunctionNameMapper['ASVP'],
  agent_id: [],
  status: 2
};

function FormInstructions(props: FormInstructionsProps) {
  const [instructionData, setInstructionData] = React.useState<FormInstruction>(defaultState);
  const [subjectEmpty, setSubjectEmpty] = React.useState<boolean>(false);
  const [descriptionEmpty, setDescriptionEmpty] = React.useState<boolean>(false);
  const [statusEmpty, setStatusEmpty] = React.useState<boolean>(false);
  const [dateError, setDateError] = React.useState<boolean>(false);

  const { formTitle, closeModalHandler, data, actionType, submitHandler } = props;

  const formInstructionInError = React.useRef(false);

  const classes = useStyles();

  const closeModal = () => {
    setInstructionData(defaultState);
    closeModalHandler();
  };

  const validateRequiredTextField = () => {
    let isValid = true;

    if (textFieldIsEmpty(instructionData.subject) === true) {
      isValid = false;
      setSubjectEmpty(true);
    }
    if (textFieldIsEmpty(instructionData.description) === true) {
      isValid = false;
      setDescriptionEmpty(true);
    }
    if (textFieldIsEmpty(instructionData.status) === true) {
      isValid = false;
      setStatusEmpty(true);
    }

    if (new Date(instructionData.start_date).getTime() > new Date(instructionData.end_date).getTime()) {
      isValid = false;
      setDateError(true);
    }

    return isValid;
  };

  const handleChange = (fieldName: string, fieldValue: string | string[] | number | Date) => {
    setInstructionData((prev) => ({
      ...prev,
      [fieldName]: fieldValue
    }));
  };

  const formatInstructionData = (insctrutionData: FormInstruction): CreateInstructionPayload | UpdateInstructionPayload => {
    let formattedInstructionData = {
      start_date: insctrutionData.start_date,
      end_date: insctrutionData.end_date,
      subject: insctrutionData.subject,
      description: insctrutionData.description,
      function: insctrutionData.function,
      agent_id: insctrutionData.agent_id,
      status: insctrutionData.status
    };

    return formattedInstructionData;
  };

  const submit = () => {
    if (formInstructionInError.current === true) {
      return;
    }
    const validation = validateRequiredTextField();
    if (validation === false) {
      return;
    }

    const formattedInstructionData = formatInstructionData(instructionData);

    submitHandler && submitHandler(formattedInstructionData, data && data.id);
    closeModal();
  };

  React.useEffect(() => {
    actionType === 'create' && setInstructionData(defaultState);
    actionType === 'update' &&
      data &&
      setInstructionData({
        start_date: data.start_date,
        end_date: data.end_date,
        subject: data.subject,
        description: data.description,
        function: data.function,
        agent_id: data.agent_id,
        status: data.status
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography component='h2' variant='h6'>
          {formTitle}
        </Typography>
      </div>
      <div className={classes.body}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputStatus' }}
              variant='outlined'
              id='status'
              label={t`Statut`}
              fullWidth
              size='small'
              select
              error={statusEmpty}
              helperText={statusEmpty ? t`requis` : ''}
              value={instructionData.status || 'normal'}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('status', event.target.value);
              }}
            >
              {constants.statusOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {t`${option.label}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputFunction' }}
              variant='outlined'
              id='function'
              label={t`Fonction`}
              fullWidth
              size='small'
              select
              value={instructionData.function || constants.FunctionNameMapper['ASVP']}
              onChange={(e) => handleChange('function', e.target.value)}
            >
              {constants.functionNameOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputAgentId' }}
              id='agent_id'
              label='Liste matricules'
              fullWidth
              size='small'
              variant='outlined'
              helperText={<Trans>Si multiple matricules, séparés par virgules. Ex : "007,567"</Trans>}
              value={
                instructionData.agent_id && instructionData.agent_id.length > 1
                  ? instructionData.agent_id.join(',')
                  : instructionData.agent_id && instructionData.agent_id.length === 1
                  ? instructionData.agent_id[0]
                  : ''
              }
              onChange={(e) => {
                const regex = /[,]/g; //find ','
                const matches = e.target.value.match(regex);
                const value = matches ? e.target.value.split(',') : [e.target.value];

                handleChange('agent_id', value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Date début`}
                inputFormat={'dd/MM/yyyy'}
                value={instructionData.start_date || new Date()}
                onChange={(event) => handleChange('start_date', event as Date)}
                renderInput={(params) => {
                  params.error = false;
                  params.size = 'small';

                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Date fin`}
                value={instructionData.end_date || new Date()}
                onChange={(event) => handleChange('end_date', event as Date)}
                renderInput={(params) => {
                  params.size = 'small';
                  params.error = dateError;
                  params.helperText = dateError ? t`Date de fin doit être supérieur à la date de début` : '';

                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputSubject' }}
              variant='outlined'
              id='subject'
              label='Libellé'
              size='small'
              fullWidth
              error={subjectEmpty}
              helperText={subjectEmpty ? t`requis` : ''}
              value={instructionData.subject || ''}
              lengthRestriction={200}
              onChange={(e) => handleChange('subject', e.target.value)}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputDescription' }}
              variant='outlined'
              id='description'
              label='Description'
              size='small'
              fullWidth
              multiline
              rows={4}
              error={descriptionEmpty}
              helperText={descriptionEmpty ? t`requis` : ''}
              value={instructionData.description || ''}
              onChange={(e) => handleChange('description', e.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <Button variant='contained' onClick={closeModal} color='primary'>
          <Trans>Annuler</Trans>
        </Button>
        <Button data-testid='modalUpdateCreateConfirmButton' style={{ marginLeft: 8 }} variant='contained' color='primary' onClick={submit}>
          <Trans>Valider</Trans>
        </Button>
      </div>
    </div>
  );
}

export default FormInstructions;
