import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Trans, t } from '@lingui/macro';
import frLocale from 'date-fns/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from 'components/TextField/TextField';
import { localeDateToISO8601, toLocaleDateString } from 'utils/dateFormatter';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import { MifSent, fetchMifSent, Filter, Response } from 'services/mifsSentServices';
import { StyledDataGrid } from '../../components/DataGrid';
import { SortOrder, Pagination } from 'utils/sortTable';
import useCommonStyles from 'commonStyles';
import useStyles from './styles';

type MifsSentProps = RouteComponentProps;

function MifsSent(props: MifsSentProps) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [mifsSent, setMifsSent] = useState<MifSent[]>([]);
  const [count, setCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>({ name: '', direction: '' });
  const [pagination, setPagination] = useState<Pagination>({ page: 0, rowsPerPage: 10 });
  const filters = useRef<Filter>({});

  const fetchMifsSentAsync = async (withFilters: boolean = false) => {
    const mifsSentServicesResponse: Response | undefined = await fetchMifSent(
      pagination,
      sortOrder,
      withFilters ? filters.current : undefined
    );
    if (mifsSentServicesResponse) {
      setMifsSent(mifsSentServicesResponse.datas);
      setCount(mifsSentServicesResponse.total);
    }
  };

  useEffect(() => {
    fetchMifsSentAsync();
  }, [pagination, sortOrder]);

  const fetchMifsSentWithFilters = async () => {
    fetchMifsSentAsync(true);
  };

  const resetFilters = async () => {
    filters.current = {};
    fetchMifsSentAsync();
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue = filterFromDataTable[changedColumnIndex][0] ? filterFromDataTable[changedColumnIndex][0] : undefined;

    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is = undefined || = "all"
    if (filterFromDataTable[changedColumnIndex][0] === undefined || filterFromDataTable[changedColumnIndex][0] === 'all') {
      delete filters.current[columnName as keyof Filter];
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'file_name',
      label: t`Nom du fichier`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Nom du fichier: ${v}` },
        filterType: 'textField'
      }
    },
    {
      name: 'matricule',
      label: t`Agent`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Agent: ${v}` },
        filterType: 'textField'
      }
    },
    {
      name: 'date',
      label: t`Date`,
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: { render: (v) => t`Date: ${v}` },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Date`}
                inputFormat={'dd/MM/yyyy'}
                value={filterList[index][0] || ''}
                onChange={(event: Date | null) => {
                  filterList[index] = [localeDateToISO8601(event)];
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => {
                  params.error = false;
                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{toLocaleDateString(mifsSent[rowIndex].date)}</span>
      }
    },
    {
      name: 'state',
      label: t`État`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`État: ${v}` },
        filterType: 'textField'
      }
    },
    {
      name: 'action',
      label: t`Action`,
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => t`Action: ${v}` },
        filterType: 'textField'
      }
    }
  ];

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <StyledDataGrid
            title={t`Liste des MIFs envoyés`}
            data={mifsSent}
            columns={columns}
            options={{
              filter: true,
              filterType: 'dropdown',
              responsive: 'standard',
              serverSide: true,
              count: count,
              page: pagination.page,
              rowsPerPage: pagination.rowsPerPage,
              tableBodyHeight: 'auto',
              tableBodyMaxHeight: '600px',
              onColumnSortChange: (changedColumn, direction) => {
                setSortOrder({ name: changedColumn, direction: direction });
              },
              onChangePage: (page) => {
                setPagination({ page: page, rowsPerPage: pagination.rowsPerPage });
              },
              onChangeRowsPerPage(numberOfRows) {
                setPagination({ page: pagination.page, rowsPerPage: numberOfRows });
              },
              onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
              },
              onFilterChipClose(index, removedFilter, filterList) {
                buildFitlers(removedFilter as string | null, filterList, index);
                fetchMifsSentWithFilters();
              },
              customFilterDialogFooter: () => {
                return (
                  <div className={commonClasses.customFilterDialogFooter}>
                    <Button variant='text' onClick={() => resetFilters()}>
                      <Trans>Tout effacer</Trans>
                    </Button>
                    <Button
                      className={commonClasses.customFilterDialogFooterApplyButotn}
                      variant='contained'
                      onClick={() => fetchMifsSentWithFilters()}
                    >
                      <Trans>Appliquer</Trans>
                    </Button>
                  </div>
                );
              }
            }}
            components={{
              TableHead: (props) => {
                return <TableHead {...props} />;
              },
              TableToolbar: (props) => {
                return <TableToolbar {...props} />;
              }
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default MifsSent;
