import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import {
  Agents,
  Decree,
  ConnectCnt,
  Equipments,
  Instructions,
  MifsInError,
  MifsSendingHistory,
  MifsSent,
  MifsToSend,
  Photos,
  Radars,
  RecoverFvv,
  RecoverMifsManually,
  ResendMifs,
  SendMifs,
  SendMifsFullProcess,
  Statistics,
  UpdateEncryptionKey,
  VerifyMifsSending
} from 'pages';
import { and } from '@egis-auth/authorization';

import { RoutePath } from './routes';

export enum roles {
  AGENT = 'USER', //agent
  MANAGER = 'MANAGER', //superviseur
  ADMIN = 'ADMIN' //administrateur
}

function Router() {
  return (
    <Switch>
      <ProtectedRoute exact path={RoutePath.HOME} component={Statistics} />
      <ProtectedRoute exact path={RoutePath.MIFS_TO_SEND} component={MifsToSend} />
      <ProtectedRoute path={RoutePath.MIFS_IN_ERROR} component={MifsInError} />
      <ProtectedRoute path={RoutePath.MIFS_SENT} component={MifsSent} />
      <ProtectedRoute path={RoutePath.MIFS_SENDING_HISTORY} component={MifsSendingHistory} />
      <ProtectedRoute path={RoutePath.SEND_MIFS} component={SendMifs} />
      <ProtectedRoute path={RoutePath.VERIFY_MIFS_SENDING} component={VerifyMifsSending} />
      <ProtectedRoute path={RoutePath.RESEND_MIFS} component={ResendMifs} />
      <ProtectedRoute path={RoutePath.SEND_MIFS_FULL_PROCESS} component={SendMifsFullProcess} />
      <ProtectedRoute path={RoutePath.UPDATE_ENCRYPTION_KEY} component={UpdateEncryptionKey} />
      <ProtectedRoute path={RoutePath.RECOVER_FVV} component={RecoverFvv} />
      <ProtectedRoute path={RoutePath.CONNECT_CNT} component={ConnectCnt} />
      <ProtectedRoute path={RoutePath.RECOVER_MIFS_MANUALLY} component={RecoverMifsManually} />
      <ProtectedRoute path={RoutePath.EQUIPMENTS} component={Equipments} />
      <ProtectedRoute path={RoutePath.AGENTS} component={Agents} />
      <ProtectedRoute path={RoutePath.BYLAWS} component={Decree} />
      <ProtectedRoute path={RoutePath.INSTRUCTIONS} component={Instructions} />
      <ProtectedRoute path={RoutePath.RADARS} component={Radars} />
      <ProtectedRoute path={RoutePath.PHOTOS} component={Photos} />
    </Switch>
  );
}

export default Router;
