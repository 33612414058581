import { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import { StyledDataGrid } from '../../components/DataGrid';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { MifSendingHistory, fetchMifSendingHistory, Response, FileName } from 'services/mifsSendingHistoryServices';
import { SortOrder, Pagination } from 'utils/sortTable';
import { toLocaleDateString } from 'utils/dateFormatter';
import useStyles from './styles';

type Props = {
  data: FileName;
};

function Collapsible(props: Props) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [count, setCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>({ name: '', direction: '' });
  const [pagination, setPagination] = useState<Pagination>({ page: 0, rowsPerPage: 10 });
  const [mifsSendingHistory, setMifsSendingHistory] = useState<MifSendingHistory[]>([]);

  useEffect(() => {
    async function fetchMifsSendingHistoryAsync() {
      const mifSendingHistoryServicesResponse: Response | undefined = await fetchMifSendingHistory(
        pagination,
        sortOrder,
        props.data.ckmif_file_name
      );
      if (mifSendingHistoryServicesResponse) {
        setMifsSendingHistory(mifSendingHistoryServicesResponse.datas);
        setCount(mifSendingHistoryServicesResponse.total);
      }
    }

    fetchMifsSendingHistoryAsync();
  }, [props.data, pagination, sortOrder]);

  const handleClick = () => {
    setOpen(!open);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'file_name',
      label: t`Nom du fichier`
    },
    {
      name: 'state',
      label: t`État`
    },
    {
      name: 'action',
      label: t`Action`
    },

    {
      name: 'reemit_number',
      label: t`Numéro réémission`
    }
  ];

  return (
    <div className={classes.historyBloc}>
      <Button className={classes.historyHeader} onClick={handleClick}>
        <Typography component='h3'>
          {toLocaleDateString(props.data.date)} : {props.data.ckmif_file_name}
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <StyledDataGrid
          title={''}
          data={mifsSendingHistory}
          columns={columns}
          options={{
            download: false,
            filter: false,
            search: false,
            viewColumns: false,
            serverSide: true,
            count: count,
            page: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
            tableBodyHeight: 'auto',
            tableBodyMaxHeight: '600px',
            onColumnSortChange: (changedColumn, direction) => {
              setSortOrder({ name: changedColumn, direction: direction });
            },
            onChangePage: (page) => {
              setPagination({ page: page, rowsPerPage: pagination.rowsPerPage });
            },
            onChangeRowsPerPage(numberOfRows) {
              setPagination({ page: pagination.page, rowsPerPage: numberOfRows });
            }
          }}
          components={{
            TableHead: (props) => {
              return <TableHead {...props} />;
            },
            TableToolbar: (props) => {
              return <TableToolbar {...props} />;
            }
          }}
        />
      </Collapse>
    </div>
  );
}

export default Collapsible;
