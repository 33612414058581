import { EventEmitter } from 'events';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';
import { MapBrowserEvent, Overlay } from 'ol';
import { MapOptions } from 'ol/PluggableMap';
import { Pixel } from 'ol/pixel';
import { StyleLike } from 'ol/style/Style';
import Feature, { FeatureLike } from 'ol/Feature';
import Layer from 'ol/layer/Layer';
import Map from 'ol/Map';
import OsmSource from 'ol/source/OSM';
import OverlayPositioning from 'ol/OverlayPositioning';
import Point from 'ol/geom/Point';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Source from 'ol/source/Source';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View, { ViewOptions } from 'ol/View';

import { isPointFeature, markerStyle } from './utils';
import Geometry from 'ol/geom/Geometry';

const defaultViewOptions: ViewOptions = {
  center: fromLonLat([2.2137, 46.2276]),
  zoom: 5
};
const defaultPhotoMarkerStyle: StyleLike = markerStyle(32, 32, '#fff');
const defaultHoveredPhotoMarkerStyle: StyleLike = markerStyle(32, 32, '#555');
const defaultSelectedPhotoMarkerStyle: StyleLike = markerStyle(32, 32, '#000');
const defaultPhotoMarkerHitTolerance: number = 32;

export type PhotoMapOptions = MapOptions & {
  viewOptions: ViewOptions;
  photoMarkerStyle?: StyleLike;
  hoveredPhotoMarkerStyle?: StyleLike;
  selectedPhotoMarkerStyle?: StyleLike;
  photoMarkerHitTolerance?: number;
  photoMarkerPopup?: HTMLElement | null;
};

class PhotoMap extends EventEmitter {
  public readonly map: Map;
  public readonly initialPhotoMapOptions: PhotoMapOptions;
  public readonly photoMarkersSource: VectorSource<Geometry>;
  public readonly photoMarkersLayer: VectorLayer<VectorSource<Geometry>>;
  public hoveredPhotoMarker: Feature<Point> | null = null;
  public selectedPhotoMarker: Feature<Point> | null = null;
  private view: View | null = null;
  private _photoMarkerStyle: StyleLike;
  private _hoveredPhotoMarkerStyle: StyleLike;
  private _selectedPhotoMarkerStyle: StyleLike;
  private _photoMarkerHitTolerance: number;
  private _photoMarkerSelectInteraction: Select;
  private _photoMarkerOverlay: Overlay;

  public constructor(photoMapOptions: PhotoMapOptions) {
    super();

    this.initialPhotoMapOptions = photoMapOptions;

    const {
      viewOptions = defaultViewOptions,
      photoMarkerStyle = defaultPhotoMarkerStyle,
      hoveredPhotoMarkerStyle = defaultHoveredPhotoMarkerStyle,
      selectedPhotoMarkerStyle = defaultSelectedPhotoMarkerStyle,
      photoMarkerHitTolerance = defaultPhotoMarkerHitTolerance,
      photoMarkerPopup,
      target
    } = photoMapOptions;

    this.view = new View({ center: viewOptions.center, zoom: viewOptions.zoom });
    this._photoMarkerStyle = photoMarkerStyle;
    this._hoveredPhotoMarkerStyle = hoveredPhotoMarkerStyle;
    this._selectedPhotoMarkerStyle = selectedPhotoMarkerStyle;
    this._photoMarkerHitTolerance = photoMarkerHitTolerance;

    const mapOptions: MapOptions = {
      target,
      layers: [
        new TileLayer({
          source: new OsmSource()
        })
      ],
      view: this.view
    };

    this.map = new Map(mapOptions);

    this.photoMarkersSource = new VectorSource();
    this.photoMarkersLayer = new VectorLayer({
      source: this.photoMarkersSource
    });

    this._photoMarkerSelectInteraction = new Select({
      layers: [this.photoMarkersLayer],
      style: this.selectedPhotoMarkerStyle,
      hitTolerance: this.photoMarkerHitTolerance
    });

    this._photoMarkerSelectInteraction.on('select', this.handleSelect.bind(this));

    this._photoMarkerOverlay = new Overlay({
      element: photoMarkerPopup === null ? undefined : photoMarkerPopup,
      offset: [0, -32],
      positioning: OverlayPositioning.BOTTOM_CENTER
    });

    this.map.addOverlay(this._photoMarkerOverlay);
    this.map.addLayer(this.photoMarkersLayer);
    this.map.addInteraction(this.photoMarkerSelectInteraction);
    this.map.on('pointermove', this.handlePointerMove.bind(this));
  }

  public centerMap(position: number[]) {
    if (!this.view || !this.map) {
      return;
    }
    this.map.getView().setCenter(position);
    this.map.getView().setZoom(15);
  }

  public displayMarkerOverlay(el: any, position: number[]) {
    const overlay = new Overlay({
      element: el,
      offset: [0, 0],
      position
    });

    this.map.addOverlay(overlay);
  }

  private handlePointerMove(event: MapBrowserEvent<UIEvent>) {
    this.hoverPhotoMarker(event.pixel);
  }

  private hoverPhotoMarker(pixel: Pixel) {
    if (this.hoveredPhotoMarker !== null) {
      if (this.hoveredPhotoMarker !== this.selectedPhotoMarker) {
        this.hoveredPhotoMarker.setStyle(this.photoMarkerStyle);
      }
      this.hoveredPhotoMarker = null;
    }

    this.map.forEachFeatureAtPixel(
      pixel,
      (feature: FeatureLike) => {
        if (!isPointFeature(feature)) return false;
        if (feature === this.selectedPhotoMarker) return false;

        this.hoveredPhotoMarker = feature;
        feature.setStyle(this.hoveredPhotoMarkerStyle);

        return true;
      },
      {
        layerFilter: (layer: Layer<Source, any>) => layer === this.photoMarkersLayer,
        hitTolerance: this.photoMarkerHitTolerance
      }
    );
  }

  public addPhotoMarker(coordinates: Coordinate) {
    const photoMarkerPoint = new Point(coordinates);
    const photoMarkerFeature = new Feature({
      geometry: photoMarkerPoint
    });
    photoMarkerFeature.setStyle(this.photoMarkerStyle);

    this.photoMarkersSource.addFeature(photoMarkerFeature);
  }

  public get photoMarkerStyle(): StyleLike {
    return this._photoMarkerStyle;
  }

  public set photoMarkerStyle(style: StyleLike) {
    this._photoMarkerStyle = style;
  }

  public get hoveredPhotoMarkerStyle(): StyleLike {
    return this._hoveredPhotoMarkerStyle;
  }

  public set hoveredPhotoMarkerStyle(style: StyleLike) {
    this._hoveredPhotoMarkerStyle = style;
  }

  public get selectedPhotoMarkerStyle(): StyleLike {
    return this._selectedPhotoMarkerStyle;
  }

  public set selectedPhotoMarkerStyle(style: StyleLike) {
    this._selectedPhotoMarkerStyle = style;
  }

  public get photoMarkerHitTolerance(): number {
    return this._photoMarkerHitTolerance;
  }

  public set photoMarkerHitTolerance(hitTolerance: number) {
    this._photoMarkerHitTolerance = hitTolerance;
  }

  public get photoMarkerSelectInteraction(): Select {
    return this._photoMarkerSelectInteraction;
  }

  public set photoMarkerSelectInteraction(selectInteraction: Select) {
    this.map.removeInteraction(this.photoMarkerSelectInteraction);
    this.map.addInteraction(selectInteraction);

    selectInteraction.on('select', this.handleSelect.bind(this));

    this._photoMarkerSelectInteraction = selectInteraction;
  }

  private handleSelect(event: SelectEvent) {
    let selectedPhotoMarker: Feature<Point> | null = null;

    if (event.selected.length > 0) {
      const selectedFeature = event.selected[0];
      if (isPointFeature(selectedFeature)) {
        this.openPhotoMarkerPopup(selectedFeature.getGeometry()?.getCoordinates());
        selectedPhotoMarker = selectedFeature;
      }
    }

    if (selectedPhotoMarker === null) this.closePhotoMarkerPopup();

    this.selectedPhotoMarker = selectedPhotoMarker;
  }

  private openPhotoMarkerPopup(position?: Coordinate) {
    this.photoMarkerOverlay.setPosition(position);
  }

  private closePhotoMarkerPopup() {
    this._photoMarkerOverlay.setPosition(undefined);
  }

  public get photoMarkerOverlay(): Overlay {
    return this._photoMarkerOverlay;
  }
}

export default PhotoMap;
