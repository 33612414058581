import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import trafficLightRed from '../../assets/traffic-light/red.png';
import { Tooltip } from '@mui/material';
// import trafficLightGreen from "../../assets//traffic-light/green.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  trafficLight: {
    border: '2px solid #c0baba'
  }
}));

export type TrafficLightProps = {
  children?: React.ReactNode;
};

function TrafficLight(props: TrafficLightProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title="Non connecté à l'ANTAI">
        <img src={trafficLightRed} alt="Non connecté à l'ANTAI" className={classes.trafficLight}></img>
      </Tooltip>
    </div>
  );
}

export default TrafficLight;
