import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  networkUsageStack: number;
} = {
  networkUsageStack: 0
};

export const networkUsageSlice = createSlice({
  name: 'networkUsage',
  initialState,
  reducers: {
    addOneNetworkUsage: (state) => {
      state.networkUsageStack = state.networkUsageStack + 1;
    },
    removeOneNetworkUsage: (state) => {
      if (state.networkUsageStack - 1 < 0) {
        return;
      }
      state.networkUsageStack = state.networkUsageStack - 1;
    }
  }
});

export const { addOneNetworkUsage, removeOneNetworkUsage } = networkUsageSlice.actions;

export default networkUsageSlice.reducer;
