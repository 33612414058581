import Axios from './Axios';
import { store } from 'store';

export interface Equipment {
  id: number;
  antai_id: string;
  serial_number: string;
  mac_adress: string | null;
  corps: string | null;
  function: string | null;
  service_code: string | null;
  brand: string;
  model: string;
  state: string;
  site: number | null;
  sim_sn: string | null;
  battery_sn: string | null;
  created_at: string;
  updated_at: string;
}

export type CreateEquipmentPayload = {
  antai_id: string;
  serial_number: string;
  brand: string;
  model: string;
  state: string;
  mac_adress?: string | null;
  corps?: string | null;
  function?: string | null;
  service_code?: string | null;
  site?: number | null;
  sim_sn?: string | null;
  battery_sn?: string | null;
};

export type UpdateEquipmentPayload = Omit<Equipment, 'id'>;

export type Filters = Omit<Partial<Equipment>, 'id'>;

export const fetchEquipment = async (filters: Filters = {}): Promise<Equipment[]> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Equipment[]>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/equipment',
    params: filters,
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const fetchEquipmentById = async (id: string): Promise<Equipment> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Equipment>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/equipment/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const createEquipment = async (craeteequipmentPayload: CreateEquipmentPayload): Promise<Equipment> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Equipment>({
    method: 'post',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/equipment',
    data: craeteequipmentPayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const updateEquipment = async (updateequipmentPayload: UpdateEquipmentPayload, id: number): Promise<Equipment> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Equipment>({
    method: 'put',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/equipment/${id}`,
    data: updateequipmentPayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const deleteEquipment = async (id: number): Promise<Equipment> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Equipment>({
    method: 'delete',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/equipment/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};
