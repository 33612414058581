import React, { useRef } from 'react';
import * as photoServices from 'services/photoServices';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import View from 'ol/View';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { MapBrowserEvent, Overlay } from 'ol';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Fade, IconButton, Modal } from '@mui/material';
import { Photo } from 'services/photoServices';
import { toLocaleDateString, toLocaleTimeString } from 'utils/dateFormatter';

export type Props = {
  photoData: Photo[];
  mapCenterCoordinate?: number[];
  style?: React.CSSProperties;
};

const OLMap = (props: Props) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [imgUrl, setImgUrl] = React.useState<string | undefined>(undefined);
  const [selectedData, setSelectedData] = React.useState<{ agent_id: string | any; datetime: string | any } | undefined>();

  const map = React.useRef<Map | undefined>(undefined);

  const displayPopover = async (evt: MapBrowserEvent<any>) => {
    var feature =
      map.current &&
      map.current.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });

    const photoFileBase64: string | undefined = await photoServices.getPhotoFileBinaryData(feature?.getProperties().fileName as string);

    photoFileBase64 && setImgUrl(photoFileBase64);
    feature && setSelectedData({ agent_id: feature?.getProperties().agentId, datetime: feature?.getProperties().datetime });

    const popup = new Overlay({
      element: document.getElementById('popup') as HTMLElement,
      id: 'popup'
    });
    //@ts-ignore
    popup.setPosition(feature?.getGeometry().flatCoordinates);
    map.current && map.current.addOverlay(popup);
  };

  React.useEffect(() => {
    const markers = props.photoData.map((photo, index) => {
      const marker = new Feature({
        geometry: new Point(fromLonLat([photo.lng, photo.lat]))
      });

      marker.setProperties({
        datetime: photo.datetime,
        agentId: photo.agent_id,
        fileName: photo.file_name
      });

      marker.setStyle(
        new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: './marker.png'
          })
        })
      );

      return marker;
    });

    const vectorSource = new VectorSource({
      features: markers
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource
    });

    map.current?.setTarget(undefined);

    map.current = new Map({
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        vectorLayer
      ],
      target: 'map',
      view: new View({
        center: fromLonLat([props.photoData[0].lng, props.photoData[0].lat]),
        zoom: 15
      })
    });
  }, [props.photoData]);

  React.useEffect(() => {
    if (!map.current) {
      return;
    }

    if (props.mapCenterCoordinate && props.mapCenterCoordinate.length < 2) {
      return;
    }

    map.current.getView().setCenter(props.mapCenterCoordinate);
  }, [props.mapCenterCoordinate]);

  React.useEffect(() => {
    if (!map.current) {
      return;
    }
    map.current.on('click', (evt) => displayPopover(evt));
  }, [map.current]);

  return (
    <React.Fragment>
      <div
        style={{
          width: props.style?.width || '100%',
          height: props.style?.height || '100%',
          ...props.style
        }}
        id='map'
      ></div>
      <div>
        <div
          id='popup'
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            willChange: 'transform',
            width: 400,
            height: 200,
            borderRadius: 4,
            background: '#FFFFFF',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
            transform: 'translate3d(-200px, -210px, 0px)'
          }}
        >
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <IconButton onClick={() => setOpenModal(true)} style={{ position: 'absolute', right: 0, top: 0 }}>
              <FullscreenIcon />
            </IconButton>
            <img
              id='image'
              alt='agent_photo'
              src={`data:image/*;base64,${imgUrl}`}
              style={{ height: 180, paddingTop: 20, marginRight: 'auto', marginLeft: 'auto', display: 'block' }}
            />
            <div>
              <span>
                <b>Matricule:</b> {selectedData?.agent_id}
              </span>
              <span>
                <b>Date:</b> {toLocaleDateString(selectedData?.datetime)} {toLocaleTimeString(selectedData?.datetime)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <IconButton onClick={() => setOpenModal(false)} style={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon style={{ color: '#FFFFFF' }} />
            </IconButton>
            <img
              style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }}
              alt='agent_photo'
              src={`data:image/*;base64,${imgUrl}`}
              id='agent_photo'
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default OLMap;
