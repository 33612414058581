import { Theme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    padding: 8
  },
  path: {
    color: theme.palette.grey[100],
    height: 24,
    display: 'flex',
    padding: 0,
    fontSize: '75%',
    lineHeight: ' 75%',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRadius: 16,
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: theme.palette.grey[400],
    paddingLeft: 8,
    paddingRight: 8,
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
      textDecoration: 'none'
    }
  },
  currentPage: {
    height: 24,
    display: 'flex',
    padding: 0,
    fontSize: '75%',
    fontWeight: 'bolder',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    textOverflow: 'ellipsis'
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#FFF'
  },

  icon: {
    fontSize: 12,
    marginRight: 4
  },
  homeIcon: {
    fontSize: 12
  }
}));

export default useStyles;
