export const toLocaleDateString = (date: string | number | Date): string => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('fr');
};

export const toLocaleTimeString = (date: string | number | Date): string => {
  if (!date) {
    return '';
  }
  //new Date(date).toLocaleTimeString('fr-FR');
  return new Date(date).toLocaleTimeString('fr-FR', { timeZone: 'UTC', timeZoneName: 'short' }).replace('UTC', '');
};

export const getDateNowISO8601 = (): string => {
  return new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`).toISOString();
};

export const localeStringDateToISO8601 = (localeDate: string): string => {
  const date = new Date(localeDate);

  return new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`).toISOString();
};

export const localeDateToISO8601 = (date: Date | null, dateOnly: boolean = true): string => {
  if (!date) {
    return '';
  }

  const localeDateToISO8601 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`).toISOString();

  if (dateOnly) {
    return localeDateToISO8601.substring(0, localeDateToISO8601.indexOf('T'));
  }

  return localeDateToISO8601;
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
};

export const defaultDateNow = () => {
  const date: Date = new Date();
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-');
};
