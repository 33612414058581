import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  content: {
    // margin: 16,
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
    fontSize: '1.5rem'
  },
  customGrid: {
    justifyContent: 'center'
  },
  card: {
    height: '100%'
  },
  customButtonHeader: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    }
  },
  customButtonContent: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0
  },
  dateSearch: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: 8,
    justifyContent: 'space-between',
    display: 'flex'
  },
  MuiOutlinedInput: {
    input: {
      padding: 0
    }
  },
  photoMap: {
    width: '100%',
    height: '100%'
  }
}));

export default useStyles;
