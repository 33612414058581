import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    height: '100%',
    marginBottom: 20
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    marginBottom: 10
  },
  card: {
    padding: 16,
    marginBottom: 24,
    width: '100%'
  },
  component: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  label: {
    fontSize: '1.1rem',
    fontWeight: 'bolder'
  },
  p: {
    fontStyle: 'italic',
    color: 'grey'
  },
  cardMifSent: {
    padding: 16,
    marginBottom: 24
  },
  cardFvv: {
    padding: 16,
    marginBottom: 24,
    marginRight: 24,
    width: '100%'
  },
  cardCollectInfos: {
    padding: 16,
    marginBottom: 24,
    marginLeft: 24,
    width: 400
  }
}));

export default useStyles;
