import React from 'react';
import Container from '@mui/material/Container';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDataGrid } from '../../components/DataGrid';
import Dialog from '@mui/material/Dialog';
import FormAgents from './FormAgents';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Trans, t } from '@lingui/macro';
import { TableCell, TableRow } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import { fetchAgent, updateAgent, createAgent, deleteAgent } from 'services/agentServices';
import { Agent, CreateAgentPayload, Filter, UpdateAgentPayload } from 'services/agentServices';
import useStyles from './styles';
import useCommonStyles from 'commonStyles';
import { sortData, Temp } from 'utils/sortTable';

function Agents() {
  const [agents, setAgents] = React.useState<Agent[]>([]);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<Agent>();
  const [count, setCount] = React.useState<number>(0);

  const selectedId = React.useRef<undefined | number>(undefined);
  const filters = React.useRef<Filter>({});

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const openDialogEdit = (data: Agent) => () => {
    setSelectedData(data);
    setOpenEditDialog(true);
  };

  const openDialogDelete = (id: number) => () => {
    selectedId.current = id;
    setOpenDelete(true);
  };

  const closeDialogDelete = () => {
    setOpenDelete(false);
  };

  const deleteItem = async () => {
    setOpenDelete(false);
    const deletedAgentServiceResponse = await deleteAgent(selectedId.current as number);

    const updatedAgentsState = agents.filter((agent) => agent.id !== deletedAgentServiceResponse.id);

    setAgents(updatedAgentsState);
  };

  const updateAgentHandler = async (updateAgentPayload: UpdateAgentPayload, id: number) => {
    const updateAgentServiceResponse = await updateAgent(updateAgentPayload, id);

    const updatedAgentsState = agents.map((agent) => {
      if (agent.id === updateAgentServiceResponse.id) {
        return updateAgentServiceResponse;
      }

      return agent;
    });

    setAgents(updatedAgentsState);
  };

  const createAgentHandler = async (createAgentPayload: CreateAgentPayload) => {
    const createdAgentServiceResponse = await createAgent(createAgentPayload);
    setAgents([...agents, createdAgentServiceResponse]);
  };

  const fetchAgentsWithFilters = async function () {
    const agentServiceResponse = await fetchAgent(filters.current);

    setAgents(agentServiceResponse);
  };

  const resetFilters = async () => {
    filters.current = {};
    const agentServiceResponse = await fetchAgent();

    setAgents(agentServiceResponse);
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue = filterFromDataTable[changedColumnIndex][0];
    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is empty
    if (filterFromDataTable[changedColumnIndex][0] === undefined) {
      delete filters.current[columnName as keyof Filter];
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'agent_id',
      label: t`Matricule`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Matricule: ${value}`
        }
      }
    },
    {
      name: 'name',
      label: t`Nom`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Nom: ${value}`
        }
      }
    },
    {
      name: 'surname',
      label: t`Prénom`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Prénom: ${value}`
        }
      }
    },
    {
      name: 'service_code',
      label: t`Code Service`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Code Service: ${value}`
        }
      }
    },
    {
      name: 'function',
      label: t`Fonction`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Fonction: ${value}`
        }
      }
    },
    {
      name: 'card_serial',
      label: t`Numéro Carte`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Numéro carte: ${value}`
        }
      }
    },
    {
      name: 'enabled',
      label: t`Actif`,
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{value ? 'Oui' : 'Non'}</div>;
        },
        customFilterListOptions: {
          render: (value: string[]) => (value[0] === 'true' ? t`Actif: Oui` : value[0] === 'false' ? t`Actif: Non` : t`Actif: Tout`)
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const selectedValueMapper: { [index: string]: string } = {
              true: 'Oui',
              false: 'Non'
            };
            return (
              <FormControl variant='standard'>
                <InputLabel>
                  <Trans>Actif</Trans>
                </InputLabel>
                <Select
                  defaultValue={'Tout'}
                  value={filterList[index][0] || 'Tout'}
                  renderValue={(selected) => selectedValueMapper[selected]}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  <MenuItem>
                    <Trans>Tout</Trans>
                  </MenuItem>
                  <MenuItem value='true'>
                    <Trans>Oui</Trans>
                  </MenuItem>
                  <MenuItem value='false'>
                    <Trans>Non</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      name: 'equipment_serial',
      label: t`Num série équipement`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => `Num série équipement: ${value}`
        }
      }
    },

    {
      name: 'Actions',
      label: t`Actions`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: 80 }
        }),
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <div>
            <Tooltip title={<Trans>Modifier cet agent</Trans>}>
              <IconButton
                className={classes.customButtonContent}
                onClick={openDialogEdit(agents[dataIndex])}
                size='large'
                data-testid={`updateAgent${rowIndex}`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Trans>Supprimer cet agent</Trans>}>
              <IconButton
                className={classes.customButtonContent}
                onClick={openDialogDelete(agents[dataIndex].id)}
                size='large'
                data-testid={`deleteAgent${rowIndex}`}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ];

  React.useEffect(() => {
    async function fetchAgentAsync() {
      const agentServiceResponse = await fetchAgent();

      setAgents(agentServiceResponse);
      setCount(agentServiceResponse.length);
    }

    fetchAgentAsync();
  }, []);

  const sort = (column: string, order: string) => {
    let temp: Temp = {
      column: column,
      order: order,
      data: agents
    };

    setAgents([...sortData(temp)]);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <StyledDataGrid
            title={<Trans>Gestion des agents</Trans>}
            data={agents}
            columns={columns}
            options={{
              serverSide: true,
              count: count,
              pagination: false,
              tableBodyHeight: 'auto',
              tableBodyMaxHeight: '600px',
              onColumnSortChange: (changedColumn, direction) => {
                sort(changedColumn, direction);
              },
              onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
              },
              onFilterChipClose(index, removedFilter, filterList) {
                buildFitlers(removedFilter as string | null, filterList, index);
                fetchAgentsWithFilters();
              },
              customToolbar: () => (
                <Tooltip title={<Trans>Ajouter un agent</Trans>}>
                  <IconButton
                    className={classes.customButtonHeader}
                    onClick={() => setOpenCreateDialog(true)}
                    size='large'
                    data-testid='createAgent'
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ),
              customFilterDialogFooter: () => {
                return (
                  <div className={commonClasses.customFilterDialogFooter}>
                    <Button variant='text' onClick={() => resetFilters()}>
                      <Trans>Tout effacer</Trans>
                    </Button>
                    <Button
                      className={commonClasses.customFilterDialogFooterApplyButotn}
                      variant='contained'
                      onClick={() => fetchAgentsWithFilters()}
                    >
                      <Trans>Appliquer</Trans>
                    </Button>
                  </div>
                );
              },
              customFooter: () => {
                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell className={classes.customFooter}>
                        <Trans>Total : {count}</Trans>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              }
            }}
            components={{
              TableHead: (props) => {
                return <TableHead {...props} />;
              },
              TableToolbar: (props) => {
                return <TableToolbar {...props} />;
              }
            }}
          />
        </div>
      </Container>
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <FormAgents
          actionType='create'
          formTitle={t`Ajouter un agent`}
          submitHandler={(agent) => createAgentHandler(agent as CreateAgentPayload)}
          closeModalHandler={() => setOpenCreateDialog(false)}
        />
      </Dialog>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <FormAgents
          actionType='update'
          formTitle={t`Modifier un agent`}
          submitHandler={(agent, id) => updateAgentHandler(agent as UpdateAgentPayload, id as number)}
          closeModalHandler={() => setOpenEditDialog(false)}
          data={selectedData}
        />
      </Dialog>

      <Dialog open={openDelete} onClose={closeDialogDelete}>
        <DialogTitle className={classes.dialogHeader} id='form-dialog-title'>
          <Trans>Supprimer</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>Etes-vous sur de vous supprimer cette agent ?</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogDelete} color='primary' variant='contained'>
            <Trans>Non</Trans>
          </Button>
          <Button onClick={deleteItem} color='primary' style={{ marginLeft: 8 }} variant='contained' data-testid='confirmDeleteAgent'>
            <Trans>Oui</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Agents;
