import Axios from './Axios';
import { store } from 'store';

export interface Decree {
  id: number;
  type: number;
  decree_id: string;
  date: string;
  description: string;
  natinf: string[];
  created_at: string;
  updated_at: string;
}

export interface CreateDecreePayload {
  id: number;
  type: number;
  decree_id: string;
  date: string;
  description: string;
  natinf: string[];
}

export type UpdateDecreePayload = Omit<Decree, 'id' | 'created_at' | 'updated_at'>;

export type Filter = Omit<Partial<Decree>, 'id'>;

export const fetchDecree = async (filters: Filter = {}): Promise<Decree[]> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Decree[]>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/decree',
    params: filters,
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const fetchDecreeById = async (id: string): Promise<Decree> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Decree>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/decree/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const createDecree = async (craetedecreePayload: CreateDecreePayload): Promise<Decree> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Decree>({
    method: 'post',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/decree',
    data: craetedecreePayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const updateDecree = async (updatedecreePayload: UpdateDecreePayload, id: number): Promise<Decree> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Decree>({
    method: 'put',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/decree/${id}`,
    data: updatedecreePayload,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};

export const deleteDecree = async (id: number): Promise<Decree> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Decree>({
    method: 'delete',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/decree/${id}`,
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};
