import React from 'react';
import frLocale from 'date-fns/locale/fr';
import TextField from 'components/TextField/TextField';
import Container from '@mui/material/Container';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDataGrid } from '../../components/DataGrid';
import Dialog from '@mui/material/Dialog';
import FormDecree from './FormDecrees';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Trans, t } from '@lingui/macro';
import { TableCell, TableRow } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import { fetchDecree, updateDecree, createDecree, deleteDecree } from 'services/decreeServices';
import { CreateDecreePayload, Decree, Filter, UpdateDecreePayload } from 'services/decreeServices';
import { toLocaleDateString, localeDateToISO8601 } from 'utils/dateFormatter';
import * as constants from './constants';
import { noSelectionLabel, noSelectionValue } from 'constants/constants';
import useCommonStyles from 'commonStyles';
import useStyles from './styles';
import { sortData, Temp } from 'utils/sortTable';

function Decrees() {
  const [decrees, setDecrees] = React.useState<Decree[]>([]);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<Decree>();
  const [count, setCount] = React.useState<number>(0);

  const selectedId = React.useRef<undefined | number>(undefined);
  const filters = React.useRef<Filter>({});

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const openDialogEdit = (data: Decree) => () => {
    setSelectedData(data);
    setOpenEditDialog(true);
  };

  const openDialogDelete = (id: number) => () => {
    selectedId.current = id;
    setOpenDelete(true);
  };

  const closeDialogDelete = () => {
    setOpenDelete(false);
  };

  const deleteItem = async () => {
    setOpenDelete(false);
    const deletedDecreeServiceResponse = await deleteDecree(selectedId.current as number);

    const updatedDecreesState = decrees.filter((decree) => decree.id !== deletedDecreeServiceResponse.id);

    setDecrees(updatedDecreesState);
  };

  const updateDecreeHandler = async (updateDecreePayload: UpdateDecreePayload, id: number) => {
    const updateDecreeServiceResponse = await updateDecree(updateDecreePayload, id);

    const updatedDecreeState = decrees.map((decree) => {
      if (decree.id === updateDecreeServiceResponse.id) {
        return updateDecreeServiceResponse;
      }

      return decree;
    });

    setDecrees(updatedDecreeState);
  };

  const createDecreeHandler = async (createDecreePayload: CreateDecreePayload) => {
    const createdDecreeServiceResponse: Decree = await createDecree(createDecreePayload);

    setDecrees([...decrees, createdDecreeServiceResponse]);
  };

  const fetchDecreesWithFilters = async () => {
    const decreeServiceResponse = await fetchDecree(filters.current);

    setDecrees(decreeServiceResponse);
  };

  const resetFilters = async () => {
    filters.current = {};
    const decreeServiceResponse = await fetchDecree();

    setDecrees(decreeServiceResponse);
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue =
      columnName === 'natinf'
        ? filterFromDataTable[changedColumnIndex][0] && filterFromDataTable[changedColumnIndex][0].split(',')
        : filterFromDataTable[changedColumnIndex][0]
        ? filterFromDataTable[changedColumnIndex][0]
        : undefined;

    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is = undefined || = "all"
    if (filterFromDataTable[changedColumnIndex][0] === undefined || filterFromDataTable[changedColumnIndex][0] === 'all') {
      delete filters.current[columnName as keyof Filter];
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'type',
      label: t`Type d'arrêtés`,
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => constants.decreeTypeCodeMapper[value]
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='type'
                label={t`Type d'arrêtés`}
                select
                fullWidth
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {constants.decreeTypeOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <span>{t`${constants.decreeTypeCodeMapper[decrees[rowIndex].type]}`}</span>
        )
      }
    },
    {
      name: 'decree_id',
      label: t`Référence`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Référence`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'date',
      label: t`Date mise en service`,
      options: {
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => `Date: ${value}`
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Date mise en service`}
                inputFormat={'dd/MM/yyyy'}
                value={filterList[index][0] || ''}
                onChange={(event: Date | null) => {
                  filterList[index] = [localeDateToISO8601(event)];
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => {
                  params.error = false;
                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{toLocaleDateString(decrees[rowIndex].date)}</span>
      }
    },

    {
      name: 'description',
      label: t`Description`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Description`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'natinf',
      label: t`NatInf`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`NatInf`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <span>{decrees[rowIndex].natinf && decrees[rowIndex].natinf.join(', ')}</span>
        )
      }
    },

    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: 80 }
        }),
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <div>
            <Tooltip title={'Modifier cet arrêté'}>
              <IconButton className={classes.customButtonContent} onClick={openDialogEdit(decrees[dataIndex])} size='large'>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Supprimer cet arrêté'}>
              <IconButton
                className={classes.customButtonContent}
                onClick={decrees.length > 0 ? openDialogDelete(decrees[dataIndex].id) : undefined}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ];

  React.useEffect(() => {
    async function fetchDecreeAsync() {
      const instructionServicesResponse: Decree[] = await fetchDecree();

      setDecrees(instructionServicesResponse);
      setCount(instructionServicesResponse.length);
    }

    fetchDecreeAsync();
  }, []);

  const sort = (column: string, order: string) => {
    let temp: Temp = {
      column: column,
      order: order,
      data: decrees
    };

    setDecrees([...sortData(temp)]);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <StyledDataGrid
            title={'Gestion des arrêtés'}
            data={decrees}
            columns={columns}
            options={{
              serverSide: true,
              count: count,
              pagination: false,
              tableBodyHeight: 'auto',
              tableBodyMaxHeight: '600px',
              onColumnSortChange: (changedColumn, direction) => {
                sort(changedColumn, direction);
              },
              onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
              },
              onFilterChipClose(index, removedFilter, filterList) {
                buildFitlers(removedFilter as string | null, filterList, index);
                fetchDecreesWithFilters();
              },
              customToolbar: () => (
                <Tooltip title={'Ajouter un arrété'}>
                  <IconButton className={classes.customButtonHeader} onClick={() => setOpenCreateDialog(true)} size='large'>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ),
              customFilterDialogFooter: () => {
                return (
                  <div className={commonClasses.customFilterDialogFooter}>
                    <Button variant='text' onClick={() => resetFilters()}>
                      <Trans>Tout effacer</Trans>
                    </Button>
                    <Button
                      className={commonClasses.customFilterDialogFooterApplyButotn}
                      variant='contained'
                      onClick={() => fetchDecreesWithFilters()}
                    >
                      <Trans>Appliquer</Trans>
                    </Button>
                  </div>
                );
              },
              customFooter: (count) => {
                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell className={classes.customFooter}>
                        <Trans>Total : {count}</Trans>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              }
            }}
            components={{
              TableHead: (props) => {
                return <TableHead {...props} />;
              },
              TableToolbar: (props) => {
                return <TableToolbar {...props} />;
              }
            }}
          />
        </div>
      </Container>
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <FormDecree
          actionType='create'
          formTitle={t`Ajouter un arrêté`}
          submitHandler={(decree) => createDecreeHandler(decree as CreateDecreePayload)}
          closeModalHandler={() => setOpenCreateDialog(false)}
        />
      </Dialog>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <FormDecree
          actionType='update'
          formTitle={t`Modifier un arrêté`}
          submitHandler={(decree, id) => updateDecreeHandler(decree as UpdateDecreePayload, id as number)}
          closeModalHandler={() => setOpenEditDialog(false)}
          data={selectedData}
        />
      </Dialog>

      <Dialog open={openDelete} onClose={closeDialogDelete}>
        <DialogTitle className={classes.dialogHeader} id='form-dialog-title'>
          Supprimer
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>Etes-vous sur de vous supprimer l'arrété ?</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogDelete} color='primary' variant='contained'>
            <Trans>Non</Trans>
          </Button>
          <Button type='submit' onClick={deleteItem} color='primary' style={{ marginLeft: 8 }} variant='contained'>
            <Trans>Oui</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Decrees;
