type CodeMapper = {
  [index: number]: string;
};

export const decreeTypeCodeMapper: CodeMapper = {
  1: 'Municipal',
  2: 'Préfectoral',
  3: 'Conseil Général'
};

export enum DecreeTypeLabelMapper {
  MUNICIPAL = 1,
  PREFECTORAL = 2,
  CONSEIL_GENERAL = 3
}

export const decreeTypeOptions = [
  {
    value: 1,
    label: 'Municipal'
  },
  {
    value: 2,
    label: 'Prefectoral'
  },
  {
    value: 3,
    label: 'Conseil Général'
  }
];
