import { createTheme } from '@mui/material/styles';
// import { teal, indigo } from "@mui/material/colors";

const defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#53b5ba',
      main: '#28A3A9',
      dark: '#1c7276',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffc784',
      main: '#FFB966',
      dark: '#b28147',
      contrastText: '#000'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 8
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: 4,
          marginRight: 4
        }
      }
    }
  }
});

export default defaultTheme;
