import { AnimationOptions } from 'ol/View';
import { Coordinate } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import Icon from 'ol/style/Icon';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import IconOrigin from 'ol/style/IconOrigin';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import compose from 'lodash/fp/compose';

export const svgIconStyle = (icon: string) =>
  new Style({
    image: new Icon({
      anchorOrigin: IconOrigin.TOP_LEFT,
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      anchor: [0.5, 1],
      src: `data:image/svg+xml;utf8,${escape(icon)}`
    })
  });

export const markerIcon = (width: number, height: number, color: string) =>
  `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 24"><path fill="${color}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>`;

export const markerStyle = compose(svgIconStyle, markerIcon);

export const center = (map: Map, coordinates: Coordinate) => {
  map.getView().setCenter(coordinates);
};

export const zoom = (map: Map, zoom: number) => {
  map.getView().setZoom(zoom);
};

export const animate = (map: Map, animationOptions: AnimationOptions) => {
  const view = map.getView();

  if (view.getAnimating()) view.cancelAnimations();

  view.animate(animationOptions);
};

export const isPointFeature = (feature: FeatureLike): feature is Feature<Point> => feature.getGeometry()?.getType() === GeometryType.POINT;
