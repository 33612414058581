import { toLocaleDateString } from 'utils/dateFormatter';

/**
 * function format datas for graph
 * @param datas
 * @param previousDate
 * @param lastDate
 * @returns { date: string, num: number}[]
 */
export const formatDataGraphDateAndNum = (datas: any[], previousDate: Date, lastDate: Date) => {
  let res: { date: string; num: number }[] = [];
  for (previousDate; previousDate <= lastDate; previousDate.setDate(previousDate.getDate() + 1)) {
    const currentDate: string = toLocaleDateString(previousDate);

    if (datas.some((item) => toLocaleDateString(item.date) === currentDate))
      res.push({ date: currentDate, num: Number(datas.filter((item) => toLocaleDateString(item.date) === currentDate)[0].num) });
    else res.push({ date: currentDate, num: 0 });
  }
  return res;
};

/**
 * function format datas for graph
 * @param datas
 * @param previousDate
 * @param lastDate
 * @returns { date: string; sent: number; error: number }[]
 */
export const getDataGraphDateAndSentAndError = (datas: any[], previousDate: Date, lastDate: Date) => {
  let res: { date: string; sent: number; error: number }[] = [];

  for (previousDate; previousDate <= lastDate; previousDate.setDate(previousDate.getDate() + 1)) {
    const currentDate: string = toLocaleDateString(previousDate);

    if (datas.some((item) => toLocaleDateString(item.date) === currentDate))
      res.push({
        date: currentDate,
        sent: Number(datas.filter((item) => toLocaleDateString(item.date) === currentDate)[0].sent),
        error: Number(datas.filter((item) => toLocaleDateString(item.date) === currentDate)[0].error)
      });
    else res.push({ date: currentDate, sent: 0, error: 0 });
  }

  return res;
};
