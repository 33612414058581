import { store } from 'store';
import Axios from './Axios';
import { Pagination, SortOrder } from 'utils/sortTable';

export type Photo = {
  id: number;
  agent_id: string;
  datetime: string;
  num_ordre: number;
  lat: number;
  lng: number;
  file_name: string;
  file_path: string;
  created_at: string;
};

export type Response = {
  datas: Photo[];
  total: number;
};

export type Filters = Omit<Partial<Photo>, 'id'>;

export const fetchPhoto = async (pagination: Pagination, sort: SortOrder, filters: Filters = {}): Promise<Response | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Response>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/photo',
    params: {
      filters,
      pagination,
      sort
    },
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const getPhotoFileBinaryData = async (fileName: string): Promise<string | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<string>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/photo/${fileName}`,
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};
