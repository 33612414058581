import React from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HistoryIcon from '@mui/icons-material/History';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ReplayIcon from '@mui/icons-material/Replay';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TrafficIcon from '@mui/icons-material/Traffic';
import PanToolIcon from '@mui/icons-material/PanTool';
import MouseIcon from '@mui/icons-material/Mouse';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import GavelIcon from '@mui/icons-material/Gavel';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { i18nRouteLabel } from 'routes/routes';
import { RoutePath } from '../../routes';
import useStyles from './drawerContentStyles';
import { Trans } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { toggleDrawer } from 'reducers/layoutSlice';

function DrawerContent() {
  const [exploitationExpanded, setExploitationExpanded] = React.useState(false);
  const [mifsStateExpanded, setMifsStateExpanded] = React.useState(false);
  const [commandsExpanded, setCommandsExpanded] = React.useState(false);
  const [managementExpanded, setManagementExpanded] = React.useState(false);

  const userAccountUrl = useAppSelector((state) => state.auth.userAccountUrl);
  const reduxDispatch = useAppDispatch();
  const classes = useStyles();

  const handleExploitationClick = () => setExploitationExpanded(!exploitationExpanded);
  const handleMIFsStateClick = () => setMifsStateExpanded(!mifsStateExpanded);
  const handleCommandsClick = () => setCommandsExpanded(!commandsExpanded);
  const handleManagementClick = () => setManagementExpanded(!managementExpanded);

  return (
    <React.Fragment>
      <List component='div' disablePadding>
        {/* Home */}
        <ListItem className={classes.menuItemlvl0} button>
          <ListItemIcon className={classes.menuItemIcon}>
            <HomeIcon className={classes.menuIconlvl0} />
          </ListItemIcon>
          <NavLink
            onClick={() => reduxDispatch(toggleDrawer())}
            className={`${classes.menuItemTextlvl0} ${classes.link}`}
            to={RoutePath.HOME}
            replace
          >
            <Trans id={i18nRouteLabel.home.id} />
          </NavLink>
        </ListItem>

        {/* Account */}
        <ListItem className={classes.menuItemlvl0} button>
          <ListItemIcon className={classes.menuItemIcon}>
            <AssignmentIndIcon className={classes.menuIconlvl0} />
          </ListItemIcon>
          <a className={`${classes.menuItemTextlvl0} ${classes.link}`} href={userAccountUrl}>
            <Trans id={i18nRouteLabel.account.id} />
          </a>
        </ListItem>

        {/* Exploitation */}
        <ListItem className={classes.menuItemlvl0} button onClick={handleExploitationClick}>
          <ListItemIcon className={classes.menuItemIcon}>
            <BuildIcon className={classes.menuIconlvl0} />
          </ListItemIcon>
          <ListItemText className={classes.menuItemTextlvl0} disableTypography>
            <Trans id={i18nRouteLabel.exploitation.id} />
          </ListItemText>
          {exploitationExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={exploitationExpanded} timeout='auto'>
          <List component='div' disablePadding>
            {/* exploitation / MIFs state */}
            <ListItem className={classes.menuItemlvl1} button onClick={handleMIFsStateClick}>
              <ListItemIcon className={classes.menuItemIcon}>
                <FindInPageIcon className={classes.menuIconlvl1} />
              </ListItemIcon>
              <ListItemText className={classes.menuItemTextlvl1} disableTypography>
                <Trans id={i18nRouteLabel.mifsState.id} />
              </ListItemText>
              {mifsStateExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mifsStateExpanded} timeout='auto'>
              <List component='div' disablePadding>
                {/* explqoitation / MIFs state / MIFs to send */}
                <ListItem className={classes.menuItemlvl2} button>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <TelegramIcon className={classes.menuIconlvl2} />
                  </ListItemIcon>
                  <NavLink
                    onClick={() => reduxDispatch(toggleDrawer())}
                    className={`${classes.menuItemTextlvl2} ${classes.link}`}
                    to={RoutePath.MIFS_TO_SEND}
                  >
                    <Trans id={i18nRouteLabel.mifsToSend.id} />
                  </NavLink>
                </ListItem>
                {/* explqoitation / MIFs state / MIFs in error */}
                <ListItem className={classes.menuItemlvl2} button>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <WarningIcon className={classes.menuIconlvl2} />
                  </ListItemIcon>
                  <NavLink
                    onClick={() => reduxDispatch(toggleDrawer())}
                    className={`${classes.menuItemTextlvl2} ${classes.link}`}
                    to={RoutePath.MIFS_IN_ERROR}
                  >
                    <Trans id={i18nRouteLabel.mifsInError.id} />
                  </NavLink>
                </ListItem>
                {/* explqoitation / MIFs state / MIFs sent */}
                <ListItem className={classes.menuItemlvl2} button>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <ThumbUpIcon className={classes.menuIconlvl2} />
                  </ListItemIcon>
                  <NavLink
                    onClick={() => reduxDispatch(toggleDrawer())}
                    className={`${classes.menuItemTextlvl2} ${classes.link}`}
                    to={RoutePath.MIFS_SENT}
                  >
                    <Trans id={i18nRouteLabel.mifsSent.id} />
                  </NavLink>
                </ListItem>
                {/* explqoitation / MIFs state / MIFs sent */}
                <ListItem className={classes.menuItemlvl2} button>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <HistoryIcon className={classes.menuIconlvl2} />
                  </ListItemIcon>
                  <NavLink
                    onClick={() => reduxDispatch(toggleDrawer())}
                    className={`${classes.menuItemTextlvl2} ${classes.link}`}
                    to={RoutePath.MIFS_SENDING_HISTORY}
                  >
                    <Trans id={i18nRouteLabel.mifsSendingHistory.id} />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>

        {/* Management */}
        <ListItem className={classes.menuItemlvl0} button onClick={handleManagementClick}>
          <ListItemIcon className={classes.menuItemIcon}>
            <MouseIcon className={classes.menuIconlvl0} />
          </ListItemIcon>
          <ListItemText className={classes.menuItemTextlvl0} disableTypography>
            <Trans id={i18nRouteLabel.management.id} />
          </ListItemText>
          {managementExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={managementExpanded} timeout='auto'>
          {/* Management / Equipments */}
          <ListItem className={classes.menuItemlvl1} button>
            <ListItemIcon className={classes.menuItemIcon}>
              <PhoneAndroidIcon className={classes.menuIconlvl1} />
            </ListItemIcon>
            <NavLink
              onClick={() => reduxDispatch(toggleDrawer())}
              className={`${classes.menuItemTextlvl1} ${classes.link}`}
              to={RoutePath.EQUIPMENTS}
            >
              <Trans id={i18nRouteLabel.equipments.id} />
            </NavLink>
          </ListItem>
          <ListItem className={classes.menuItemlvl1} button>
            <ListItemIcon className={classes.menuItemIcon}>
              <EmojiPeopleIcon className={classes.menuIconlvl1} />
            </ListItemIcon>
            <NavLink
              onClick={() => reduxDispatch(toggleDrawer())}
              className={`${classes.menuItemTextlvl1} ${classes.link}`}
              to={RoutePath.AGENTS}
            >
              <Trans id={i18nRouteLabel.agents.id} />
            </NavLink>
          </ListItem>
          {/* Management / Decree */}
          <ListItem className={classes.menuItemlvl1} button>
            <ListItemIcon className={classes.menuItemIcon}>
              <GavelIcon className={classes.menuIconlvl1} />
            </ListItemIcon>
            <NavLink
              onClick={() => reduxDispatch(toggleDrawer())}
              className={`${classes.menuItemTextlvl1} ${classes.link}`}
              to={RoutePath.BYLAWS}
            >
              <Trans id={i18nRouteLabel.decree.id} />
            </NavLink>
          </ListItem>
          {/* Management / Instructions */}
          <ListItem className={classes.menuItemlvl1} button>
            <ListItemIcon className={classes.menuItemIcon}>
              <ListAltIcon className={classes.menuIconlvl1} />
            </ListItemIcon>
            <NavLink
              onClick={() => reduxDispatch(toggleDrawer())}
              className={`${classes.menuItemTextlvl1} ${classes.link}`}
              to={RoutePath.INSTRUCTIONS}
            >
              <Trans id={i18nRouteLabel.instructions.id} />
            </NavLink>
          </ListItem>
        </Collapse>

        {/* Photos */}
        <ListItem className={classes.menuItemlvl0} button onClick={handleManagementClick}>
          <ListItemIcon className={classes.menuItemIcon}>
            <PhotoLibraryIcon className={classes.menuIconlvl0} />
          </ListItemIcon>
          <NavLink
            onClick={() => reduxDispatch(toggleDrawer())}
            className={`${classes.menuItemTextlvl0} ${classes.link}`}
            to={RoutePath.PHOTOS}
          >
            <Trans id={i18nRouteLabel.photos.id} />
          </NavLink>
        </ListItem>
      </List>
    </React.Fragment>
  );
}

export default DrawerContent;
