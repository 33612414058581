import React from 'react';
import { Trans } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import useStyles from './styles';
import { findRoutePathThatEndsWith } from 'routes';
import routePathAndLabel from './routePathAndLabel';

export type RoutedBreadcrumbsProps = Omit<BreadcrumbsProps, 'children'>;

function RoutedBreadcrumbs(props: RoutedBreadcrumbsProps) {
  const [breadcrumbsParts, setBreadcrumbsParts] = React.useState<
    {
      routePath: string | undefined;
      i18nRouteLabel: MessageDescriptor | undefined;
    }[]
  >([]);

  const { ...other } = props;
  const classes = useStyles();
  const { pathname } = useLocation();

  React.useEffect(() => {
    const pathSplited = pathname.split('/');
    pathSplited.shift();

    const buildedBreadcrumbsParts = pathSplited.map((pieceOfPath) => {
      const routePath = findRoutePathThatEndsWith(pieceOfPath);

      let i18nRouteLabel: MessageDescriptor | undefined = undefined;
      routePathAndLabel.forEach((item) => {
        if (item.path === routePath) {
          i18nRouteLabel = item.label;
        }
      });

      return { routePath, i18nRouteLabel };
    });

    setBreadcrumbsParts(buildedBreadcrumbsParts);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <Breadcrumbs {...other}>
        {breadcrumbsParts.map((breadcrumbsPart) => (
          <div key={breadcrumbsPart.routePath} className={classes.path}>
            <Link className={classes.label} to={breadcrumbsPart.routePath as string}>
              {breadcrumbsPart.i18nRouteLabel && <Trans id={breadcrumbsPart.i18nRouteLabel.id} />}
            </Link>
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
}

export default RoutedBreadcrumbs;
