import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InitialState = {
  locale: 'fr' | 'en';
  language: 'Français' | 'English';
};

export const initialState: InitialState = {
  locale: 'fr',
  language: 'Français'
};

type LanguageMapper = {
  fr: 'Français';
  en: 'English';
};

const languageMapper: LanguageMapper = {
  fr: 'Français',
  en: 'English'
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    changeLocale: (state, action: PayloadAction<'fr' | 'en'>) => {
      state.locale = action.payload;
      state.language = languageMapper[action.payload];
    }
  }
});

export const { changeLocale } = i18nSlice.actions;

export default i18nSlice.reducer;
