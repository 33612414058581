import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Trans, t } from '@lingui/macro';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import { StyledDataGrid } from '../../components/DataGrid';
import { fetchKpiFvv } from 'services/statisticsServices';
import { Fvv } from 'services/statisticsServices';
import { toLocaleDateString } from 'utils/dateFormatter';
import { SortOrder, Pagination } from 'utils/sortTable';
import useStyles from './styles';

const FvvContainer = () => {
  const classes = useStyles();
  const [fvv, setFvv] = useState<Fvv[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>({ name: '', direction: '' });
  const [pagination, setPagination] = useState<Pagination>({ page: 0, rowsPerPage: 10 });

  const defaultPreviousDate: Date = new Date();
  defaultPreviousDate.setDate(defaultPreviousDate.getDate() - 30);
  const defaultDateNow: Date = new Date();

  useEffect(
    () =>
      void fetchKpiFvv(defaultPreviousDate, defaultDateNow, pagination, sortOrder).then((res) => {
        if (res) {
          setFvv(res.datas);
          setCount(res.total);
          setIsLoading(false);
        }
      }),
    [pagination, sortOrder]
  );

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'date_sync',
      label: t`Date`,
      options: {
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{fvv && toLocaleDateString(fvv[rowIndex].date_sync)}</span>
      }
    },
    {
      name: 'creation',
      label: t`Création`
    },
    {
      name: 'deletion',
      label: t`Suppression`
    }
  ];

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (fvv === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    return (
      <>
        <StyledDataGrid
          title={''}
          data={fvv}
          columns={columns}
          options={{
            download: false,
            filter: false,
            search: false,
            viewColumns: false,
            serverSide: true,
            count: count,
            page: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
            tableBodyHeight: 'auto',
            tableBodyMaxHeight: '600px',
            onColumnSortChange: (changedColumn, direction) => {
              setSortOrder({ name: changedColumn, direction: direction });
            },
            onChangePage: (page) => {
              setPagination({ page: page, rowsPerPage: pagination.rowsPerPage });
            },
            onChangeRowsPerPage(numberOfRows) {
              setPagination({ page: pagination.page, rowsPerPage: numberOfRows });
            }
          }}
          components={{
            TableHead: (props) => {
              return <TableHead {...props} />;
            },
            TableToolbar: (props) => {
              return <TableToolbar {...props} />;
            }
          }}
        />
      </>
    );
  };

  return (
    <Card className={classes.cardFvv}>
      <Typography className={classes.title} component='h1'>
        <Trans>Historique des mises à jour du FOVeS</Trans>
      </Typography>
      {component()}
    </Card>
  );
};

export default FvvContainer;
