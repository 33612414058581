import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import Marker from '@mui/icons-material/Room';
import Container from '@mui/material/Container';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { StyledDataGrid } from '../../components/DataGrid';
import ImageIcon from '@mui/icons-material/Image';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { defaultDateNow, toLocaleDateString, toLocaleTimeString } from 'utils/dateFormatter';
import { PhotoMap, markerStyle } from '../../lib/carto';
import { fromLonLat } from 'ol/proj';
import * as photoServices from 'services/photoServices';
import OLMap from 'components/OLMap/OLMap';
import useCommonStyles from 'commonStyles';
import useStyles from './styles';
import { SortOrder, Pagination } from 'utils/sortTable';

type PhotosProps = RouteComponentProps;

function Photos(props: PhotosProps) {
  const [photos, setPhoto] = useState<photoServices.Photo[]>([]);
  const [mapCenterCoordinate, setMapCenterCoordinate] = useState<number[]>([]);
  const [count, setCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>({ name: 'datetime', direction: 'desc' });
  const [pagination, setPagination] = useState<Pagination>({ page: 0, rowsPerPage: 10 });
  const filters = useRef<photoServices.Filters>({});
  const [dateFilter, setDateFilter] = useState(defaultDateNow());

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const photoMapRef = React.useRef<PhotoMap | undefined>(undefined);
  const photoMarkerPopupRef = React.useRef<HTMLDivElement>(null);

  const fetchPhoto = async (withFilters: boolean = false) => {
    const servicesResponse: photoServices.Response | undefined = await photoServices.fetchPhoto(
      pagination,
      sortOrder,
      withFilters ? filters.current : undefined
    );

    if (servicesResponse) {
      setPhoto(servicesResponse.datas);
      setCount(servicesResponse.total);
    }
  };

  const fetchPhotoWithFilters = async () => {
    fetchPhoto(true);
  };

  // ---- FILTERS ----
  const resetFilters = async () => {
    filters.current = {};
    fetchPhoto();
  };

  const addFilterDatetime = (value: string) => {
    filters.current = { ...filters.current, datetime: value };
    if (!value) {
      delete filters.current['datetime'];
    }
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue = filterFromDataTable[changedColumnIndex][0] ? filterFromDataTable[changedColumnIndex][0] : undefined;

    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is = undefined || = "all"
    if (filterFromDataTable[changedColumnIndex][0] === undefined || filterFromDataTable[changedColumnIndex][0] === 'all') {
      delete filters.current[columnName as keyof photoServices.Filters];
    }
  };

  // ---- UPLOAD IMAGE ----
  const openDialogDownloadImage = (data: any) => async () => {
    const photoFileBase64: string | undefined = await photoServices.getPhotoFileBinaryData(data.file_name as string);

    if (photoFileBase64) {
      var a = document.createElement('a');
      a.href = 'data:image/*;base64,' + photoFileBase64;
      const title = data.file_name.toUpperCase().replace('.JPG', '.PNG');
      a.download = title;
      a.click();
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'agent_id',
      label: t`Matricule`,
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (value: string) => t`Matricule: ${value}`
        }
      }
    },
    {
      name: 'datetime',
      label: t`Date`,
      options: {
        customFilterListOptions: {
          render: (value) => t`Date: ${value}`
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <span>{`${toLocaleDateString(photos[rowIndex].datetime)} ${toLocaleTimeString(photos[rowIndex].datetime)}`}</span>
        )
      }
    },
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: 50, textAlign: 'center' }
        }),
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title={t`Localiser`}>
              <IconButton
                className={classes.customButtonContent}
                onClick={() => setMapCenterCoordinate(fromLonLat([photos[rowIndex].lng, photos[rowIndex].lat]))}
                size='small'
              >
                <Marker />
              </IconButton>
            </Tooltip>
            <Tooltip title={t`Télécharger l'image`}>
              <IconButton className={classes.customButtonContent} onClick={openDialogDownloadImage(photos[dataIndex])} size='small'>
                <ImageIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ];

  React.useEffect(() => {
    fetchPhotoWithFilters();
  }, [pagination, sortOrder]);

  React.useEffect(() => {
    photoMapRef.current =
      photos.length > 0
        ? new PhotoMap({
            target: 'photoMap',
            viewOptions: { center: fromLonLat([photos[0].lng, photos[0].lat]), zoom: 15 },
            photoMarkerStyle: markerStyle(32, 32, '#28A3A9'),
            hoveredPhotoMarkerStyle: markerStyle(32, 32, '#53b5ba'),
            selectedPhotoMarkerStyle: markerStyle(32, 32, '#FFB966'),
            photoMarkerHitTolerance: 32,
            photoMarkerPopup: photoMarkerPopupRef.current
          })
        : undefined;

    photos.forEach((photo) => photoMapRef.current?.addPhotoMarker(fromLonLat([photo.lng, photo.lat])));
  }, [photos]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Container maxWidth='lg' className={classes.container}>
          <Grid container spacing={3} style={{ height: '80vh' }}>
            <Grid item md={8} sm={6} xs={12}>
              {photos.length > 0 && <OLMap photoData={photos} mapCenterCoordinate={mapCenterCoordinate} />}
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div>
                <div className={classes.dateSearch}>
                  <TextField
                    id='date'
                    label='Date'
                    type='date'
                    variant='outlined'
                    fullWidth
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      addFilterDatetime(dateFilter);
                      fetchPhotoWithFilters();
                    }}
                  >
                    <Tooltip title={t`Rechercher`}>
                      <SearchIcon />
                    </Tooltip>
                  </Button>
                </div>
                <StyledDataGrid
                  title={t`Photos`}
                  data={photos}
                  columns={columns}
                  options={{
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'standard',
                    serverSide: true,
                    count: count,
                    page: pagination.page,
                    rowsPerPage: pagination.rowsPerPage,
                    tableBodyHeight: 'auto',
                    tableBodyMaxHeight: '600px',
                    onColumnSortChange: (changedColumn, direction) => {
                      setSortOrder({ name: changedColumn, direction: direction });
                    },
                    onChangePage: (page) => {
                      setPagination({ page: page, rowsPerPage: pagination.rowsPerPage });
                    },
                    onChangeRowsPerPage(numberOfRows) {
                      setPagination({ page: pagination.page, rowsPerPage: numberOfRows });
                    },
                    onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                      buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
                    },
                    onFilterChipClose(index, removedFilter, filterList) {
                      buildFitlers(removedFilter as string | null, filterList, index);
                      fetchPhotoWithFilters();
                    },
                    customFilterDialogFooter: () => {
                      return (
                        <div className={commonClasses.customFilterDialogFooter}>
                          <Button variant='text' onClick={() => resetFilters()}>
                            <Trans>Tout effacer</Trans>
                          </Button>
                          <Button
                            className={commonClasses.customFilterDialogFooterApplyButotn}
                            variant='contained'
                            onClick={() => fetchPhotoWithFilters()}
                          >
                            <Trans>Appliquer</Trans>
                          </Button>
                        </div>
                      );
                    }
                  }}
                  components={{
                    TableHead: (props) => {
                      return <TableHead {...props} />;
                    },
                    TableToolbar: (props) => {
                      return <TableToolbar {...props} />;
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Photos;
