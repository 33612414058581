import React from 'react';
import { TextField as MUITextfield, TextFieldProps } from '@mui/material';

type Props = TextFieldProps & {
  textFieldInError?: (inError: boolean) => void;
  lengthRestriction?: number;
  characterRestriction?: string; //ex : "&@.²>"
  characterRestrictionErrorMessage?: string;
  requiredCharacter?: string; //ex : "&@.²>"
  requiredCharacterMessage?: string;
  restrictToNumber?: boolean;
};

const TextField = (props: Props) => {
  const [textFieldValue, setTextFieldValue] = React.useState<string>('');
  const [restrictCharacterError, setRestrictCharacterError] = React.useState<boolean>(false);
  const [requiredCharacterError, setRequiredCharacterError] = React.useState<boolean>(false);
  const [restrictToNumberError, setRestrictToNumberError] = React.useState<boolean>(false);

  const {
    lengthRestriction,
    characterRestriction,
    characterRestrictionErrorMessage,
    requiredCharacter,
    requiredCharacterMessage,
    restrictToNumber,
    value,
    error,
    textFieldInError,
    helperText,
    ...rest
  } = props;

  const restrictLength = (value: string, lengthRestriction: number): string => {
    let restrictedValue = value;
    if (value.length > lengthRestriction) {
      restrictedValue = value.substring(0, lengthRestriction);
    }

    return restrictedValue;
  };

  const restrictCharacter = (value: string, characterRestriction: string): boolean => {
    if (!characterRestrictionErrorMessage) {
      throw new Error('You must provide a charactere restriction error message');
    }

    const regex = new RegExp(`[${characterRestriction}]+`, 'gm');
    const match = value.match(regex);

    return match !== null;
  };

  const requireCharacter = (value: string, requiredCharacter: string) => {
    if (!requiredCharacterMessage) {
      throw new Error('You must provide a required character message');
    }

    const regex = new RegExp(`[${requiredCharacter}]+`, 'gm');
    const match = value.match(regex);
    const requiredCharacterLength = requiredCharacter.length;

    return match !== null && match !== null && match[0].length >= requiredCharacterLength;
  };

  const isNumber = (value: string): boolean => {
    if (value.trim() === '') {
      return true;
    }
    return /^-?[\d.]+(?:e-?\d+)?$/.test(value);
  };

  React.useEffect(() => {
    if (lengthRestriction) {
      setTextFieldValue(restrictLength(value as string, lengthRestriction));
    } else {
      setTextFieldValue(value as string);
    }

    characterRestriction &&
      characterRestriction.length > 0 &&
      setRestrictCharacterError(restrictCharacter(value as string, characterRestriction));

    requiredCharacter && requiredCharacter.length > 0 && setRequiredCharacterError(!requireCharacter(value as string, requiredCharacter));

    restrictToNumber && setRestrictToNumberError(!isNumber(value as string));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    if (restrictToNumberError === true || requiredCharacterError === true || restrictCharacterError === true) {
      textFieldInError && textFieldInError(true);
    } else {
      textFieldInError && textFieldInError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictToNumberError, restrictCharacterError, requiredCharacterError]);

  return (
    <MUITextfield
      error={error || restrictCharacterError || restrictToNumberError || false}
      helperText={
        helperText
          ? helperText
          : restrictCharacterError
          ? characterRestrictionErrorMessage
          : requiredCharacterError
          ? requiredCharacterMessage
          : restrictToNumberError
          ? 'Ne doit contenir que des chiffres'
          : ''
      }
      value={textFieldValue}
      {...rest}
    />
  );
};

export default TextField;
