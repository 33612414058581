import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Collapsible from './Collapsible';
import { fetchFileNameMifSendingHistory, FileName } from 'services/mifsSendingHistoryServices';
import useStyles from './styles';

type MifsSendingHistoryProps = RouteComponentProps;

function MifsSendingHistory(props: MifsSendingHistoryProps) {
  const classes = useStyles();
  const [filenameMifsSendingHistory, setFilenameMifsSendingHistory] = useState<FileName[]>([]);

  const componentDataGrid = (datas: FileName[]) => {
    return datas.map((data) => <Collapsible key={data.ckmif_file_name} data={data} />);
  };

  useEffect(() => {
    async function fetchFilenameMifsSendingHistoryAsync() {
      const mifSendingHistoryServicesResponse: FileName[] | undefined = await fetchFileNameMifSendingHistory();
      if (mifSendingHistoryServicesResponse) {
        setFilenameMifsSendingHistory(mifSendingHistoryServicesResponse);
      }
    }

    fetchFilenameMifsSendingHistoryAsync();
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <Typography className={classes.title} component='h1'>
            <Trans>Historique des logs</Trans>
          </Typography>
          <Typography component='h2' className={classes.h2}>
            <Trans>Traitement effectué</Trans>
          </Typography>
          {componentDataGrid(filenameMifsSendingHistory)}
        </div>
      </Container>
    </div>
  );
}

export default MifsSendingHistory;
