import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { ThemeProviderProps } from '@mui/styles';

import defaultDataGridTheme from './defaultDataGridTheme';

export type DataGridStyleProps = Omit<ThemeProviderProps, 'theme'> & {
  theme?: ThemeProviderProps['theme'];
};

function DataGridStyle(props: DataGridStyleProps) {
  const { theme = defaultDataGridTheme, ...other } = props;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme} {...other} />
    </StyledEngineProvider>
  );
}

export default DataGridStyle;
