import { useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Trans } from '@lingui/macro';
import { fetchKpiEncryptionKey } from 'services/statisticsServices';
import { EncryptionKey } from 'services/statisticsServices';
import { toLocaleDateString } from 'utils/dateFormatter';

type Props = {
  color: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 300,
    padding: 16,
    marginBottom: 24,
    marginRight: 24,
    backgroundColor: (props: Props) => props.color
  },
  icon: {
    width: 100,
    height: 'auto',
    margin: '0 auto',
    display: 'flex'
  }
}));

const EncryptionKeyContainer = () => {
  const [encryptionKey, setEncryptionKey] = useState<EncryptionKey | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState('#FFFFFF');
  const [warning, setWarning] = useState(false);

  const getColor = (key: EncryptionKey | undefined) => {
    switch (key?.state) {
      case 'valid':
        setWarning(false);
        return '#80D784';
      case 'expIn1month':
        setWarning(true);
        return '#F7AF67';
      case 'invalid':
        setWarning(true);
        return '#EF7968';
      default:
        return '#FFFFFF';
    }
  };

  const classes = useStyles({ color: color });

  useEffect(
    () =>
      void fetchKpiEncryptionKey().then((res) => {
        setEncryptionKey(res);
        setIsLoading(false);
        setColor(getColor(res));
      }),
    []
  );

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (encryptionKey === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    return (
      <>
        {warning ? <WarningAmberRoundedIcon className={classes.icon} /> : <VpnKeyIcon className={classes.icon} />}

        <p>
          <b>
            <Trans>Clef de chiffrement : </Trans>
          </b>

          {encryptionKey.name}
        </p>
        <p>
          <b>
            <Trans>Date d'expiration : </Trans>
          </b>

          {toLocaleDateString(encryptionKey.date)}
        </p>
      </>
    );
  };

  return <Card className={classes.card}>{component()}</Card>;
};

export default EncryptionKeyContainer;
