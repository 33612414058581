import { Theme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  dataTableContainer: {
    marginBottom: 16
  },
  customButtonHeader: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    }
  },
  customButtonContent: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  dialogHeader: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  customFooter: {
    fontSize: '.9rem',
    padding: 10
  }
}));

export default useStyles;
