import React from 'react';
import Container from '@mui/material/Container';
import frLocale from 'date-fns/locale/fr';
import { TableHead, TableToolbar, MUIDataTableColumnDef } from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledDataGrid } from '../../components/DataGrid';
import Dialog from '@mui/material/Dialog';
import FormInstructions from './FormInstructions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import { Trans, t } from '@lingui/macro';
import { TableCell, TableRow } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import TextField from 'components/TextField/TextField';
import { CreateInstructionPayload, Filter, Instruction, UpdateInstructionPayload } from 'services/instructionServices';
import { fetchInstruction, updateInstruction, createInstruction, deleteInstruction } from 'services/instructionServices';
import { localeDateToISO8601, toLocaleDateString } from 'utils/dateFormatter';
import * as constants from './constants';
import { noSelectionLabel, noSelectionValue } from 'constants/constants';
import useStyles from './styles';
import useCommonStyles from 'commonStyles';
import { sortData, Temp } from 'utils/sortTable';

function Instructions() {
  const [instructions, setInstructions] = React.useState<Instruction[]>([]);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<Instruction>();
  const [count, setCount] = React.useState<number>(0);

  const selectedId = React.useRef<undefined | number>(undefined);
  const filters = React.useRef<Filter>({});

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const openDialogEdit = (data: Instruction) => () => {
    setSelectedData(data);
    setOpenEditDialog(true);
  };

  const openDialogDelete = (id: number) => () => {
    selectedId.current = id;
    setOpenDelete(true);
  };

  const closeDialogDelete = () => {
    setOpenDelete(false);
  };

  const deleteItem = async () => {
    setOpenDelete(false);
    const deletedInstructionServiceResponse = await deleteInstruction(selectedId.current as number);

    const updatedInstructionsState = instructions.filter((instruction) => instruction.id !== deletedInstructionServiceResponse.id);

    setInstructions(updatedInstructionsState);
  };

  const updateInstructionHandler = async (updateInstructionPayload: UpdateInstructionPayload, id: number) => {
    const updateInstructionServiceResponse = await updateInstruction(updateInstructionPayload, id);

    const updatedInstructionState = instructions.map((instruction) => {
      if (instruction.id === updateInstructionServiceResponse.id) {
        return updateInstructionServiceResponse;
      }

      return instruction;
    });

    setInstructions(updatedInstructionState);
  };

  const createInstructionHandler = async (createInstructionPayload: CreateInstructionPayload) => {
    const createdInstructionServiceResponse: Instruction = await createInstruction(createInstructionPayload);

    setInstructions([...instructions, createdInstructionServiceResponse]);
  };

  const fetchInstructionsWithFilters = async () => {
    const instructionServiceResponse = await fetchInstruction(filters.current);

    setInstructions(instructionServiceResponse);
  };

  const resetFilters = async () => {
    filters.current = {};
    const instructionServiceResponse = await fetchInstruction();

    setInstructions(instructionServiceResponse);
  };

  const buildFitlers = (columnName: string | null, filterFromDataTable: string[][], changedColumnIndex: number) => {
    if (columnName === null) {
      throw new Error('You must provide a column name to manage filters');
    }

    const filterValue =
      columnName === 'agent_id'
        ? filterFromDataTable[changedColumnIndex][0] && filterFromDataTable[changedColumnIndex][0].split(',')
        : filterFromDataTable[changedColumnIndex][0]
        ? filterFromDataTable[changedColumnIndex][0]
        : undefined;

    filters.current = { ...filters.current, [columnName]: filterValue };

    //don't set filter if value is = undefined || = "all"
    if (filterFromDataTable[changedColumnIndex][0] === undefined || filterFromDataTable[changedColumnIndex][0] === 'all') {
      delete filters.current[columnName as keyof Filter];
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'status',
      label: t`Statut`,
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => constants.statusCodeToNameMapper[value]
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='status'
                label={t`Status`}
                select
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {constants.statusOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <span>{t`${constants.statusCodeToNameMapper[instructions[rowIndex].status]}`}</span>
        )
      }
    },
    {
      name: 'function',
      label: t`Fonction`,
      options: {
        filter: true,
        display: 'true',
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                id='function'
                label={t`Fonction`}
                select
                value={filterList[index][0] || noSelectionValue}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
              >
                <MenuItem value={noSelectionValue}>
                  <Trans>{noSelectionLabel}</Trans>
                </MenuItem>
                {constants.functionNameOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {t`${option.label}`}
                  </MenuItem>
                ))}
              </TextField>
            );
          }
        }
      }
    },
    {
      name: 'agent_id',
      label: t`Liste matricules`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Matricules`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];

                onChange(filterList[index], index, column);
              }}
            />
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          //custom render to display status Name instead of status Code
          <span>
            {(instructions[rowIndex] !== null &&
              instructions[rowIndex].agent_id !== null &&
              (instructions[rowIndex].agent_id as string[]).length > 0 &&
              (instructions[rowIndex].agent_id as string[]).join(',')) ||
              ''}
          </span>
        )
      }
    },
    {
      name: 'start_date',
      label: t`Date début`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Débute après le`}
                inputFormat={'dd/MM/yyyy'}
                value={filterList[index][0] || ''}
                onChange={(event: Date | null) => {
                  filterList[index] = [localeDateToISO8601(event)];
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => {
                  params.error = false;
                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{toLocaleDateString(instructions[rowIndex].start_date)}</span>
      }
    },
    {
      name: 'end_date',
      label: t`Date fin`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                label={t`Se termine avant le `}
                inputFormat={'dd/MM/yyyy'}
                value={filterList[index][0] || ''}
                clearable
                onChange={(event: Date | null) => {
                  filterList[index] = [localeDateToISO8601(event)];
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => {
                  params.error = false;
                  return <TextField variant='outlined' {...params} />;
                }}
              />
            </LocalizationProvider>
          )
        },
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => <span>{toLocaleDateString(instructions[rowIndex].end_date)}</span>
      }
    },
    {
      name: 'subject',
      label: t`Libellé`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Libellé`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: 'description',
      label: t`Description`,
      options: {
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TextField
              variant='outlined'
              label={t`Description`}
              value={filterList[index][0]}
              onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
              }}
            />
          )
        }
      }
    },
    {
      name: t`Actions`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: 80 }
        }),
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => (
          <div>
            <Tooltip title={'Modifier cette consigne'}>
              <IconButton
                className={classes.customButtonContent}
                onClick={instructions.length > 0 ? openDialogEdit(instructions[dataIndex]) : undefined}
                size='large'
                data-testid={`updateInstruction${rowIndex}`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Supprimer cette consigne'}>
              <IconButton
                className={classes.customButtonContent}
                onClick={instructions.length > 0 ? openDialogDelete(instructions[dataIndex].id) : undefined}
                size='large'
                data-testid={`deleteInstruction${rowIndex}`}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ];

  React.useEffect(() => {
    async function fetchInstructionAsync() {
      const instructionServicesResponse: Instruction[] = await fetchInstruction();

      setInstructions(instructionServicesResponse);
      setCount(instructionServicesResponse.length);
    }

    fetchInstructionAsync();
  }, []);

  const sort = (column: string, order: string) => {
    let temp: Temp = {
      column: column,
      order: order,
      data: instructions
    };

    setInstructions([...sortData(temp)]);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div className={classes.dataTableContainer}>
          <StyledDataGrid
            title={t`Gestion des consignes`}
            data={instructions}
            columns={columns}
            options={{
              serverSide: true,
              count: count,
              pagination: false,
              tableBodyHeight: 'auto',
              tableBodyMaxHeight: '600px',
              onColumnSortChange: (changedColumn, direction) => {
                sort(changedColumn, direction);
              },
              onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                buildFitlers(changedColumn as string | null, filterList, changedColumnIndex);
              },
              onFilterChipClose(index, removedFilter, filterList) {
                buildFitlers(removedFilter as string | null, filterList, index);
                fetchInstructionsWithFilters();
              },
              customToolbar: () => (
                <Tooltip title={t`Ajouter une consigne`}>
                  <IconButton
                    className={classes.customButtonHeader}
                    onClick={() => setOpenCreateDialog(true)}
                    size='large'
                    data-testid='createInstruction'
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ),
              customFilterDialogFooter: () => {
                return (
                  <div className={commonClasses.customFilterDialogFooter}>
                    <Button variant='text' onClick={() => resetFilters()}>
                      <Trans>Tout effacer</Trans>
                    </Button>
                    <Button
                      className={commonClasses.customFilterDialogFooterApplyButotn}
                      variant='contained'
                      onClick={() => fetchInstructionsWithFilters()}
                    >
                      <Trans>Appliquer</Trans>
                    </Button>
                  </div>
                );
              },
              customFooter: (count) => {
                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell className={classes.customFooter}>
                        <Trans>Total : {count}</Trans>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              }
            }}
            components={{
              TableHead: (props) => {
                return <TableHead {...props} />;
              },
              TableToolbar: (props) => {
                return <TableToolbar {...props} />;
              }
            }}
          />
        </div>
      </Container>
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <FormInstructions
          actionType='create'
          formTitle={t`Ajouter une consigne`}
          submitHandler={(instruction) => createInstructionHandler(instruction as CreateInstructionPayload)}
          closeModalHandler={() => setOpenCreateDialog(false)}
        />
      </Dialog>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <FormInstructions
          actionType='update'
          formTitle={t`Modifier une consigne`}
          submitHandler={(instruction, id) => updateInstructionHandler(instruction as UpdateInstructionPayload, id as number)}
          closeModalHandler={() => setOpenEditDialog(false)}
          data={selectedData}
        />
      </Dialog>

      <Dialog open={openDelete} onClose={closeDialogDelete}>
        <DialogTitle className={classes.dialogHeader} id='form-dialog-title'>
          <Trans>Supprimer</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>Etes-vous sur de vous supprimer la consigne ?</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogDelete} color='primary' variant='contained'>
            <Trans>Non</Trans>
          </Button>
          <Button onClick={deleteItem} color='primary' style={{ marginLeft: 8 }} variant='contained' data-testid='confirmDeleteInstruction'>
            <Trans>Oui</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Instructions;
