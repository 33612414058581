import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { addOneNetworkUsage, removeOneNetworkUsage } from 'reducers/networkUsageSlice';
import { store } from 'store';

const Axios = async <T = any, D = any>(config: AxiosRequestConfig<any>, displayLoader: boolean = true): Promise<AxiosResponse<T, D>> => {
  displayLoader && store.dispatch(addOneNetworkUsage());

  const axiosResponse: AxiosResponse<T, D> = await axios(config);

  displayLoader && store.dispatch(removeOneNetworkUsage());

  return axiosResponse as AxiosResponse;
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      window.location.href = store.getState().auth.loginUrl || window.location.pathname;
    }
  }
);

export default Axios;
