import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Trans } from '@lingui/macro';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { fetchKpiMifToSend, MifToSend } from 'services/statisticsServices';
import useStyles from './styles';

const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

const STATE = ['new', 'send-error', 'waiting-checkmif', 're-emit', 'checkmif-received', 'checkmif-error'];

const getNumberOfOneState = (mifs: MifToSend[], state: string) => {
  let result: number = 0;
  mifs.map((mif) => (result += mif.state === state ? 1 : 0));
  return result === 0 ? undefined : result;
};

const GraphMifToSend = () => {
  const classes = useStyles();
  const [mifsToSend, setMifsToSend] = useState<MifToSend[] | undefined>();
  const [currentState, setCurrentState] = useState('');
  const [dataGraph, setDataGraph] = useState<{ name: string; value: number | undefined }[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataGraph = (mifs: MifToSend[]) => {
    let data: { name: string; value: number | undefined }[] = [];
    STATE.map((state) => getNumberOfOneState(mifs, state) && data.push({ name: state, value: getNumberOfOneState(mifs, state) }));
    setCurrentState(data[0]?.name || '');
    setDataGraph(data);
  };

  useEffect(
    () =>
      void fetchKpiMifToSend().then((res) => {
        setMifsToSend(res);
        setIsLoading(false);
        if (res) getDataGraph(res);
      }),
    []
  );

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (mifsToSend === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    if (mifsToSend.length === 0) return <Trans>Pas de MIFs aujourd'hui</Trans>;

    return (
      <>
        <Box className={classes.component}>
          <Box style={{ width: 550, height: 300 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  dataKey='value'
                  nameKey='name'
                  isAnimationActive={false}
                  data={dataGraph}
                  outerRadius={80}
                  fill='#8884d8'
                  label={(entry) => `${entry.name} (${entry.value})`}
                  onClick={(e) => setCurrentState(e.name)}
                >
                  {dataGraph.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} onClick={() => setCurrentState(entry.name)} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box component='span'>
            <Typography className={classes.label} component='h2'>
              <Trans>Liste des MIFs : {currentState}</Trans>
            </Typography>
            <List sx={{ width: 400, overflow: 'auto', height: '100%', maxHeight: 230 }} dense={true}>
              {mifsToSend ? (
                mifsToSend.map(
                  (mif) =>
                    mif.state === currentState && (
                      <ListItem key={mif.id} disableGutters>
                        <ListItemText primary={`${mif.file_name}`} />
                      </ListItem>
                    )
                )
              ) : (
                <Trans>Pas de MIFs</Trans>
              )}
            </List>
          </Box>
        </Box>
        <Typography className={classes.p} component='p'>
          <Trans>*Cliquez sur une part du graphique pour afficher la liste correspondante</Trans>
        </Typography>
      </>
    );
  };

  return (
    <Card className={classes.card}>
      <Typography className={classes.title} component='h1'>
        <Trans>Nombre de MIFs à envoyer par état du jour</Trans>
      </Typography>
      {component()}
    </Card>
  );
};

export default GraphMifToSend;
