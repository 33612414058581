export const corpsOptions = [
  {
    value: 'PM',
    label: 'PM'
  },
  {
    value: 'PN',
    label: 'PN'
  },
  {
    value: 'PP',
    label: 'PP'
  },
  {
    value: 'GN',
    label: 'GN'
  }
];

export const functionNameOptions = [
  {
    value: 'ASVP',
    label: 'ASVP'
  },
  {
    value: 'APJ',
    label: 'APJ'
  },
  {
    value: 'APJA',
    label: 'APJA'
  },
  {
    value: 'OPJ',
    label: 'OPJ'
  }
];

export const stateOptions = [
  {
    value: '1',
    label: 'En service'
  },
  {
    value: '2',
    label: 'Hors service'
  }
];

export const stateCodeMapper: { [index: string]: string } = {
  '1': 'En service',
  '2': 'Hors service'
};

export const siteOptions = [
  {
    value: 1,
    label: 'Production'
  },
  {
    value: 2,
    label: 'Test'
  }
];

export const siteCodeMapper: { [index: number]: string } = {
  1: 'Production',
  2: 'Test'
};
