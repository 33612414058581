import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { drawerWidth } from 'components/Layout/ResponsiveDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

export default useStyles;
