import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from 'components/TextField/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { t, Trans } from '@lingui/macro';
import useStyles from './formEquipmentStyles';
import { corpsOptions, functionNameOptions, siteOptions, stateOptions } from './constants';
import { CreateEquipmentPayload, Equipment, UpdateEquipmentPayload } from 'services/equipmentServices';
import { textFieldIsEmpty } from 'validator/validator';
import { emptyTextFieldErrorMessage } from 'constants/constants';

export type FormEquipmentsProps = {
  formTitle: string;
  closeModalHandler: () => void;
  actionType: 'create' | 'update';
  submitHandler?: (equipmentPayload: CreateEquipmentPayload | UpdateEquipmentPayload, id?: number) => Promise<void>;
  data?: Equipment;
};

export type FormEquipment = Omit<Equipment, 'id' | 'created_at' | 'updated_at'>;

const defaultState: FormEquipment = {
  antai_id: '',
  serial_number: '',
  mac_adress: '',
  corps: 'PM',
  function: 'ASVP',
  service_code: '',
  brand: '',
  model: '',
  state: '2',
  site: 2,
  sim_sn: '',
  battery_sn: ''
};

function FormEquipments(props: FormEquipmentsProps) {
  const [equipmentData, setEquipmentData] = React.useState<FormEquipment>(defaultState);
  const [antaiIdEmpty, setAntaiIdEmpty] = React.useState<boolean>(false);
  const [serialNumberdEmpty, setSerialNumberdEmpty] = React.useState<boolean>(false);
  const [brandEmpty, setBrandEmpty] = React.useState<boolean>(false);
  const [modelEmpty, setModelEmpty] = React.useState<boolean>(false);
  const [stateEmpty, setStateEmpty] = React.useState<boolean>(false);

  const { formTitle, closeModalHandler, data, actionType, submitHandler } = props;

  const formEquipmentInError = React.useRef(false);

  const classes = useStyles();

  const closeModal = () => {
    setEquipmentData(defaultState);
    closeModalHandler();
  };

  const validateRequiredTextField = () => {
    let isValid = true;

    if (textFieldIsEmpty(equipmentData.antai_id) === true) {
      isValid = false;
      setAntaiIdEmpty(true);
    }
    if (textFieldIsEmpty(equipmentData.serial_number) === true) {
      isValid = false;
      setSerialNumberdEmpty(true);
    }
    if (textFieldIsEmpty(equipmentData.brand) === true) {
      isValid = false;
      setBrandEmpty(true);
    }
    if (textFieldIsEmpty(equipmentData.model) === true) {
      isValid = false;
      setModelEmpty(true);
    }
    if (textFieldIsEmpty(equipmentData.state) === true) {
      isValid = false;
      setStateEmpty(true);
    }

    return isValid;
  };

  const handleChange = (fieldName: string, fieldValue: string | string[] | number | Date) => {
    setEquipmentData((prev) => ({
      ...prev,
      [fieldName]: fieldValue
    }));
  };

  const formatEquipmentData = (equipmentData: FormEquipment): CreateEquipmentPayload | UpdateEquipmentPayload => {
    let formattedEquipmentData = {
      antai_id: equipmentData.antai_id,
      serial_number: equipmentData.serial_number,
      mac_adress: equipmentData.mac_adress,
      corps: equipmentData.corps,
      function: equipmentData.function,
      service_code: equipmentData.service_code,
      brand: equipmentData.brand,
      model: equipmentData.model,
      state: equipmentData.state,
      site: equipmentData.site,
      sim_sn: equipmentData.sim_sn,
      battery_sn: equipmentData.battery_sn
    };

    return formattedEquipmentData;
  };

  const submit = () => {
    if (formEquipmentInError.current === true) {
      return;
    }
    const validation = validateRequiredTextField();
    if (validation === false) {
      return;
    }

    const formattedEquipmentData = formatEquipmentData(equipmentData);

    submitHandler && submitHandler(formattedEquipmentData, data && data.id);
    closeModal();
  };

  React.useEffect(() => {
    actionType === 'create' && setEquipmentData(defaultState);
    actionType === 'update' &&
      data &&
      setEquipmentData({
        antai_id: data.antai_id,
        serial_number: data.serial_number,
        mac_adress: data.mac_adress,
        corps: data.corps,
        function: data.function,
        service_code: data.service_code,
        brand: data.brand,
        model: data.model,
        state: data.state,
        site: data.site,
        sim_sn: data.sim_sn,
        battery_sn: data.battery_sn
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography component='h2' variant='h6'>
          {formTitle}
        </Typography>
      </div>
      <div className={classes.body}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputAntaiId' }}
              autoFocus
              variant='outlined'
              id='antai_id'
              label={`${t`ID ANTAI`}${actionType === 'create' ? '*' : ''}`}
              fullWidth
              size='small'
              helperText={antaiIdEmpty ? emptyTextFieldErrorMessage : ''}
              value={equipmentData.antai_id}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('antai_id', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputSerialNumber' }}
              id='serial_number'
              label={`${t`Num série`}${actionType === 'create' ? '*' : ''}`}
              fullWidth
              size='small'
              variant='outlined'
              error={serialNumberdEmpty}
              helperText={serialNumberdEmpty ? emptyTextFieldErrorMessage : ''}
              value={equipmentData.serial_number}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('serial_number', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputMacAdress' }}
              id='mac_adress'
              label={t`Adresse MAC`}
              fullWidth
              size='small'
              variant='outlined'
              value={equipmentData.mac_adress}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('mac_adress', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputCorps' }}
              variant='outlined'
              id='corps'
              label={t`Corps`}
              select
              value={equipmentData.corps}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('corps', event.target.value);
              }}
              fullWidth
              size='small'
            >
              {corpsOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputFunction' }}
              variant='outlined'
              id='function'
              label={t`Fonction`}
              fullWidth
              size='small'
              select
              value={equipmentData.function}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('function', event.target.value);
              }}
            >
              {functionNameOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputServiceCode' }}
              variant='outlined'
              id='service_code'
              label={t`Code Service`}
              fullWidth
              size='small'
              restrictToNumber
              value={equipmentData.service_code}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('service_code', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputBrand' }}
              variant='outlined'
              id='brand'
              label={`${t`Marque`}${actionType === 'create' ? '*' : ''}`}
              fullWidth
              size='small'
              error={brandEmpty}
              helperText={brandEmpty ? emptyTextFieldErrorMessage : ''}
              value={equipmentData.brand}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('brand', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputModel' }}
              variant='outlined'
              id='model'
              label={`${t`Modèle`}${actionType === 'create' ? '*' : ''}`}
              fullWidth
              size='small'
              error={modelEmpty}
              helperText={modelEmpty ? emptyTextFieldErrorMessage : ''}
              value={equipmentData.model}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('model', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputState' }}
              variant='outlined'
              id='state'
              label={`${t`État`}${actionType === 'create' ? '*' : ''}`}
              fullWidth
              size='small'
              select
              error={stateEmpty}
              helperText={stateEmpty ? emptyTextFieldErrorMessage : ''}
              value={equipmentData.state}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('state', event.target.value);
              }}
            >
              {stateOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputSite' }}
              variant='outlined'
              id='site'
              label={t`Site`}
              fullWidth
              size='small'
              select
              value={equipmentData.site}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('site', event.target.value);
              }}
            >
              {siteOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputSimSn' }}
              variant='outlined'
              id='sim_sn'
              label={t`Carte SIM`}
              fullWidth
              size='small'
              value={equipmentData.sim_sn}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('sim_sn', event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'inputBatterySn' }}
              variant='outlined'
              id='battery_sn'
              label={t`Numéro batterie`}
              fullWidth
              size='small'
              value={equipmentData.battery_sn}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange('battery_sn', event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <Button variant='contained' onClick={closeModal} color='primary'>
          <Trans>Annuler</Trans>
        </Button>
        <Button data-testid='modalUpdateCreateConfirmButton' style={{ marginLeft: 8 }} variant='contained' color='primary' onClick={submit}>
          <Trans>Valider</Trans>
        </Button>
      </div>
    </div>
  );
}

export default FormEquipments;
