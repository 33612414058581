import { Theme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuItemIcon: {
    padding: '0 4px 0 0',
    minWidth: 'auto'
  },
  menu: {
    paddingTop: 0,
    '&:before': {
      content: "''",
      width: 1,
      height: '100%',
      left: 15,
      top: 0,
      backgroundColor: theme.palette.grey[200],
      position: 'absolute'
    }
  },
  link: {
    textDecoration: 'none'
  },
  menuItemlvl0: {
    paddingLeft: 4,
    paddingTop: 6,
    paddingBottom: 6
  },
  menuItemlvl1: {
    paddingLeft: theme.spacing(3),
    paddingTop: 2,
    paddingBottom: 2
  },
  menuItemlvl2: {
    paddingLeft: theme.spacing(5),
    paddingTop: 0,
    paddingBottom: 0
  },
  menuItemTextlvl0: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.8rem',
    width: '100%'
  },
  menuItemTextlvl1: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '0.7rem',
    width: '100%'
  },
  menuItemTextlvl2: {
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontSize: '0.7rem',
    width: '100%'
  },
  menuIconlvl0: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRadius: '100%',
    padding: 4,
    fontSize: 25
  },
  menuIconlvl1: {
    background: 'none',
    color: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
    border: '1px solid',
    borderRadius: '100%',
    padding: 2,
    fontSize: 22
  },
  menuIconlvl2: {
    background: 'none',
    color: theme.palette.grey[500],
    borderRadius: '100%',
    padding: 0,
    fontSize: 16
  }
}));

export default useStyles;
