import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useCommonStyles = makeStyles((theme: Theme) => ({
  customFilterDialogFooter: {
    width: '100%',
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  customFilterDialogFooterApplyButotn: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

export default useCommonStyles;
