import { RolesResolver } from '@egis-auth/authorization';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { hasRoles } from '@egis-auth/authorization';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { addOneNetworkUsage, removeOneNetworkUsage } from 'reducers/networkUsageSlice';

export type ProtectedRouteProps = RouteProps & {
  authenticated?: boolean;
  rolesResolver?: string | RolesResolver;
  city_id?: string[];
};

function ProtectedRoute(props: ProtectedRouteProps) {
  const [authorized, setAuthorized] = React.useState<boolean | undefined>(undefined);

  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  const loginUrl = useAppSelector((state) => state.auth.loginUrl);
  const roles = useAppSelector((state) => state.auth.roles);
  const cityId = useAppSelector((state) => state.auth.cityId);
  const loading = useAppSelector((state) => state.auth.loading);

  const reduxDispatch = useAppDispatch();

  React.useEffect(() => {
    const rolesChecker: boolean = props.rolesResolver ? hasRoles(roles, props.rolesResolver) : true;

    //TODO url params city_id

    const cityIdChecker: boolean =
      props.city_id && cityId === undefined
        ? false
        : props.city_id && cityId
        ? props.city_id.indexOf(cityId) !== -1
        : !props.city_id && true;

    rolesChecker && cityIdChecker ? setAuthorized(true) : setAuthorized(false);
  }, [cityId, props.city_id, props.rolesResolver, roles]);

  React.useEffect(() => {
    loading && reduxDispatch(addOneNetworkUsage());
    !loading && reduxDispatch(removeOneNetworkUsage());
  }, [loading, reduxDispatch]);

  if (loading === false && loggedIn === false) {
    window.location.href = loginUrl ? loginUrl : '/';
  }

  if (loading === false && loggedIn === true && authorized === false) {
    return <div>403 - UNAUTHORIZED</div>;
  }

  if (loading === false && loggedIn === true && authorized === true) {
    return <Route {...props} />;
  }

  return <React.Fragment></React.Fragment>;
}

export default ProtectedRoute;
