import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type JWT = string;

export type InitialState = {
  loggedIn: boolean | undefined;
  loginUrl: string | undefined;
  logoutUrl: string | undefined;
  userAccountUrl: string | undefined;
  token: JWT | undefined;
  roles: string[];
  cityId: string | undefined;
  loading: boolean;
};

export const initialState: InitialState = {
  loggedIn: undefined,
  loginUrl: undefined,
  logoutUrl: undefined,
  userAccountUrl: undefined,
  token: undefined,
  roles: [],
  cityId: undefined,
  loading: true
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setLoginUrl: (state, action: PayloadAction<string>) => {
      state.loginUrl = action.payload;
    },
    setLogoutUrl: (state, action: PayloadAction<string>) => {
      state.logoutUrl = action.payload;
    },
    setUserAccountUrl: (state, action: PayloadAction<string>) => {
      state.userAccountUrl = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload;
    },
    setCityId: (state, action: PayloadAction<string>) => {
      state.cityId = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

export const { setCityId, setLoggedIn, setLogoutUrl, setLoginUrl, setRoles, setToken, setUserAccountUrl, setLoading } = authSlice.actions;

export default authSlice.reducer;
