import { store } from 'store';
import Axios from './Axios';
import { SortOrder, Pagination } from 'utils/sortTable';

export type EncryptionKey = {
  id: number;
  name: string;
  active: boolean;
  date: string;
  state: string;
};

export type MifToSend = {
  id: number;
  file_name: string;
  state: string;
  created_at: Date;
};

export type PoundRemoval = {
  date: string;
  num: number;
};

export type Photo = {
  date: string;
  num: number;
};

export type MifSent = {
  date: string;
  sent: number;
  error: number;
};

export type Fvv = {
  id: number;
  date_sync: string;
  creation: number;
  deletion: number;
};

export type ResponseFvv = {
  datas: Fvv[];
  total: number;
};

export type CollectInfos = {
  agents: string;
  equipments: string;
  instructions: string;
  decrees: string;
};

export type Filters = {
  id?: string;
};

export const fetchKpiEncryptionKey = async (filters: Filters = {}): Promise<EncryptionKey | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<EncryptionKey>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/encryption-key',
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchKpiMifToSend = async (date: Date = new Date()): Promise<MifToSend[] | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<MifToSend[]>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/mif-to-send',
    headers: {
      authorization: `bearer ${jwt}`
    },
    params: {
      date: date
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchKpiPoundRemoval = async (previousDate: Date, lastDate: Date): Promise<PoundRemoval[] | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<PoundRemoval[]>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/pound-removal',
    headers: {
      authorization: `bearer ${jwt}`
    },
    params: {
      previous_date: previousDate,
      last_date: lastDate
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchKpiPhoto = async (previousDate: Date, lastDate: Date): Promise<Photo[] | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Photo[]>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/photo',
    headers: {
      authorization: `bearer ${jwt}`
    },
    params: {
      previous_date: previousDate,
      last_date: lastDate
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchKpiMifSent = async (previousDate: Date, lastDate: Date): Promise<MifSent[] | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<MifSent[]>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/mif-sent',
    headers: {
      authorization: `bearer ${jwt}`
    },
    params: {
      previous_date: previousDate,
      last_date: lastDate
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchKpiFvv = async (
  previousDate: Date,
  lastDate: Date,
  pagination: Pagination,
  sort: SortOrder
): Promise<ResponseFvv | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<ResponseFvv>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/fvv',
    headers: {
      authorization: `bearer ${jwt}`
    },
    params: {
      previous_date: previousDate,
      last_date: lastDate,
      pagination,
      sort
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const fetchKpiCollectInfos = async (filters: Filters = {}): Promise<CollectInfos[] | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<CollectInfos[]>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/kpi/collect-infos',
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};

export const getPhotoFileBinaryData = async (fileName: string): Promise<string> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<string>({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: `/photo/${fileName}`,
    headers: {
      authorization: `bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse.data;
};
