import { store } from 'store';
import Axios from './Axios';
import { SortOrder, Pagination } from 'utils/sortTable';

export type MifToSend = {
  id: number;
  file_name: string;
  date: Date;
  equipment: string;
  matricule: string;
};

export type Response = {
  datas: MifToSend[];
  total: number;
};

export type Filter = Omit<Partial<MifToSend>, 'id'>;

export const fetchMifToSend = async (pagination: Pagination, sort: SortOrder, filters: Filter = {}): Promise<Response | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Response>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/mif-to-send',
    params: {
      filters,
      pagination,
      sort
    },
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};
