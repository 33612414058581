import React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import clsx from 'clsx';
import { RoutedBreadcrumbs, RoutePath } from 'routes';
import { useLocation } from 'react-router-dom';
import exyztLogoWhite from 'assets/Egis-blanc-vert.png';
import LangSelector from 'components/LangSelector';
import logoTaeCollecte from 'assets/logo-tae-collecte-transparent.png';
import logoTaeCollecteFull from 'assets/logo-tae-collecte-gestion-des-mif-transparent.png';
import ResponsiveDrawer from 'components/Layout/ResponsiveDrawer';
import LinearProgress from '@mui/material/LinearProgress';
import TrafficLight from 'components/TrafficLight';
import DrawerContent from 'components/Layout/DrawerContent';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStyles from './layoutStyles';
import { Box } from '@mui/system';
import { toggleDrawer } from 'reducers/layoutSlice';

export type LayoutProps = {
  children?: React.ReactNode;
};

function Layout(props: LayoutProps) {
  const { children } = props;

  const { pathname } = useLocation();
  const isHomeOrLoginPage = pathname === RoutePath.HOME || pathname === RoutePath.LOGIN;

  const networkUsageStack = useAppSelector((state) => state.networkUsage.networkUsageStack);
  const openDrawer = useAppSelector((state) => state.layout.openDrawer);

  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  const logoutUrl = useAppSelector((state) => state.auth.logoutUrl);
  const reduxDispatch = useAppDispatch();

  const appBarClasses = React.useMemo(() => {
    return clsx(classes.appBar, smUp && openDrawer && classes.appBarShift);
  }, [classes.appBar, classes.appBarShift, openDrawer, smUp]);
  const contentClasses = React.useMemo(() => {
    return clsx(classes.content, smUp && openDrawer && classes.contentShift);
  }, [classes.content, classes.contentShift, openDrawer, smUp]);
  const contentClassesWithFooter = React.useMemo(() => {
    return clsx(classes.contentWithFooter, smUp && openDrawer && classes.contentShift);
  }, [classes.contentShift, classes.contentWithFooter, openDrawer, smUp]);
  const menuButtonClasses = React.useMemo(() => {
    return clsx(classes.menuButton, smUp && openDrawer && classes.hide);
  }, [classes.hide, classes.menuButton, openDrawer, smUp]);

  return (
    <div className={classes.root}>
      <AppBar className={appBarClasses} position='static'>
        <Toolbar>
          {loggedIn && (
            <IconButton
              className={menuButtonClasses}
              aria-label='open drawer'
              color='inherit'
              edge='start'
              disableRipple
              onClick={() => reduxDispatch(toggleDrawer())}
              size='large'
            >
              <MenuIcon />
            </IconButton>
          )}
          {isHomeOrLoginPage ? (
            <>
              <Box
                sx={{
                  display: {
                    xl: 'block',
                    xs: 'none'
                  }
                }}
              >
                <img className={classes.logoTaeCollecteFull} alt='TAé Collecte' src={logoTaeCollecteFull} />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none'
                  }
                }}
              >
                <img className={classes.logoTaeCollecte} alt='TAé Collecte' src={logoTaeCollecte} />
              </Box>
            </>
          ) : (
            <img className={classes.logoTaeCollecte} alt='TAé Collecte' src={logoTaeCollecte} />
          )}
          <div className={classes.spacer} />

          {loggedIn ? (
            <>
              <Box sx={{ display: { xl: 'none', xs: 'block' } }}>
                <div style={{ marginRight: 16 }}>
                  <TrafficLight />
                </div>
              </Box>
              {/* LangSelector are disabled until we need translation */}
              {/* LangSelector /> */}
              <Tooltip title='logout'>
                <IconButton aria-label='logout' color='inherit' onClick={() => (window.location.href = logoutUrl as string)} size='large'>
                  <PowerSettingsNewIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            //LangSelector are disabled until we need translation
            //<LangSelector />
            <div></div>
          )}
        </Toolbar>
        {networkUsageStack > 0 && <LinearProgress color='secondary' />}
      </AppBar>
      <ResponsiveDrawer open={openDrawer} onClose={() => reduxDispatch(toggleDrawer())}>
        <div className={classes.drawerHeader}>
          <img className={classes.logoTaeCollecte} alt='TAé Collecte' src={logoTaeCollecte} />
          <IconButton disableRipple onClick={() => reduxDispatch(toggleDrawer())} size='large'>
            {theme.direction === 'ltr' ? <ChevronLeftIcon className={classes.chevron} /> : <ChevronRightIcon className={classes.chevron} />}
          </IconButton>
        </div>

        {/* LangSelector are disabled until we need translation */}
        {/*
          <div className={classes.langSelector}>
          <LangSelector />
          </div>
        */}

        {loggedIn && (
          <div className={classes.cntKo}>
            {/* <TrafficLight /> */}
            Non connecté au CNT
          </div>
        )}

        <div className={classes.menuContainer}>
          <DrawerContent />
        </div>
      </ResponsiveDrawer>
      <div className={appBarClasses}>
        <RoutedBreadcrumbs />
      </div>
      {isHomeOrLoginPage ? (
        <main className={contentClassesWithFooter}>{children}</main>
      ) : (
        <main className={contentClasses}>{children}</main>
      )}
      {isHomeOrLoginPage && (
        <Box sx={{ display: { xl: 'block', xs: 'none' } }}>
          <AppBar position='fixed' color='primary' className={classes.bottomAppBar}>
            <Container maxWidth='md'>
              <Typography component='p' className={classes.footerText}>
                Développé par
                <img className={classes.exyztLogoWhiteBottom} alt='logo exyzt' src={exyztLogoWhite} />| 20, rue Henry Le Châtelier | Castres
                | France | Tél. : 05 63 35 33 92
              </Typography>
            </Container>
          </AppBar>
        </Box>
      )}
    </div>
  );
}

export default Layout;
