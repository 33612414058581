import { store } from 'store';
import Axios from './Axios';
import { SortOrder, Pagination } from 'utils/sortTable';

export interface MifSent {
  id: number;
  file_name: string;
  matricule: string;
  date: string;
  state: string;
  action: string;
}

export interface Response {
  datas: MifSent[];
  total: number;
}

export type Filter = Omit<Partial<MifSent>, 'id'>;

export const fetchMifSent = async (pagination: Pagination, sort: SortOrder, filters: Filter = {}): Promise<Response | undefined> => {
  const jwt = store.getState().auth.token;

  const apiResponse = await Axios<Response>({
    method: 'GET',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/mif-sent',
    params: {
      filters,
      pagination,
      sort
    },
    headers: {
      authorization: `bearer ${jwt}`
    }
  }).catch((e) => {
    throw e;
  });

  return apiResponse?.data;
};
