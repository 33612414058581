import { createTheme, Theme } from '@mui/material/styles';

const styledDataGridTheme = (theme: Theme) =>
  createTheme({
    components: {
      //@ts-ignore
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#f5f5f5'
          },
          '&:nth-child(even)': {
            backgroundColor: theme.palette.common.white
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: { '&$hover:hover': { backgroundColor: '#fff1e1' } }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '6px 8px'
          },
          body: {
            color: theme.palette.grey[700]
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white
          },
          fixedHeader: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white
          }
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
          },
          iconButton: {
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary.main
            }
          }
        }
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white
          },
          icon: {
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary.main
            },
            iconActive: {
              color: theme.palette.secondary.main,
              '&:hover': {
                color: theme.palette.common.white
              }
            }
          }
        }
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          chip: {
            margin: 8
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          stackedCommon: {
            '@media (max-width: 959.95px)': {
              width: '100%'
            }
          },
          stackedHeader: {
            '@media (max-width: 959.95px)': {
              color: theme.palette.secondary.main,
              fontWeight: 'bolder'
            }
          }
        }
      },
      MUIDataTableSearch: {
        styleOverrides: {
          main: {
            alignItems: 'center'
          },
          searchIcon: {
            color: theme.palette.common.white,
            marginTop: 0
          },
          clearIcon: {
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary.main
            }
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '@media (max-width: 959.95px)': {
              wordBreak: 'break-all',
              width: '100%'
            }
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            '@media (min-width: 600px)': {
              minHeight: 36
            }
          },
          gutters: {
            '@media (min-width: 600px)': {
              paddingLeft: 8,
              paddingRight: 8
            }
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            minHeight: 36
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 4
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: '1em',
            height: '1em',
            fontSize: '1.1rem'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          textPrimary: {
            color: theme.palette.primary.main
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            padding: '0 0 5px 0'
          }
        }
      }
    }
  });

export default styledDataGridTheme;
