import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Trans, t } from '@lingui/macro';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchKpiPoundRemoval, PoundRemoval } from 'services/statisticsServices';
import { formatDataGraphDateAndNum } from 'utils/formatDataForGraph';
import useStyles from './styles';

function GraphPoundRemoval() {
  const classes = useStyles();
  const [poundRemoval, setPoundRemoval] = useState<PoundRemoval[] | undefined>();
  const [dataGraph, setDataGraph] = useState<{ date: string; num: number }[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const defaultPreviousDate: Date = new Date();
  defaultPreviousDate.setDate(defaultPreviousDate.getDate() - 15);
  const defaultDateNow: Date = new Date();

  useEffect(
    () =>
      void fetchKpiPoundRemoval(defaultPreviousDate, defaultDateNow).then((res) => {
        setPoundRemoval(res);
        setIsLoading(false);
        if (res) setDataGraph(formatDataGraphDateAndNum(res, defaultPreviousDate, defaultDateNow));
      }),
    []
  );

  const component = () => {
    if (isLoading) return <Trans>En chargement...</Trans>;

    if (poundRemoval === undefined) return <Trans>Erreur dans la récupération des données</Trans>;

    return (
      <ResponsiveContainer width='100%' height={400}>
        <BarChart
          width={500}
          height={300}
          data={dataGraph}
          margin={{
            top: 0,
            right: 30,
            left: -10,
            bottom: 0
          }}
        >
          <XAxis
            dataKey='date'
            interval={0}
            scale='point'
            padding={{ left: 10, right: 10 }}
            tick={{ fontSize: 12 }}
            angle={-25}
            dy={12}
            height={45}
          />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign='top' align='right' />
          <CartesianGrid strokeDasharray='3 3' />
          <Bar dataKey='num' name={t`Total`} fill='#8884d8' />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Card className={classes.card}>
      <Typography className={classes.title} component='h1'>
        <Trans>Nombre de demandes d'enlèvements par jour</Trans>
      </Typography>
      {component()}
    </Card>
  );
}
export default GraphPoundRemoval;
