import React from 'react';
import DataGrid, { DataGridProps } from '../DataGrid';
import DataGridStyle from '../DataGridStyle';
import { useAppSelector } from 'hooks/useRedux';
import styledDataGridTheme from './styledDataGridTheme';
import { Trans, t } from '@lingui/macro';

export type StyledDataGridProps = DataGridProps;

function StyledDataGrid(props: StyledDataGridProps) {
  const { options, ...other } = props;

  const networkUsageStack = useAppSelector((state) => state.networkUsage.networkUsageStack);

  return (
    <DataGridStyle theme={styledDataGridTheme}>
      <DataGrid
        options={{
          textLabels: {
            pagination: {
              next: t`Page suivante`,
              previous: t`Page précédente`,
              rowsPerPage: t`Lignes par pages:`,
              displayRows: t`de`
            },
            body: {
              noMatch: networkUsageStack > 0 ? '' : <Trans>Aucune donnée disponible</Trans>,
              toolTip: t`Trier`,
              columnHeaderTooltip: (column) => t`Trie pour ${column.label}`
            },
            toolbar: {
              search: t`Rechercher`,
              downloadCsv: t`Télécharger CSV`,
              viewColumns: t`Voir les colonnes`,
              filterTable: t`Filtrer`
            },
            filter: {
              all: t`Tout`,
              title: t`Filtres`,
              reset: undefined
            }
          },
          search: false,
          rowsPerPage: 10,
          selectableRows: 'none',
          print: false,
          viewColumns: false,
          ...options
        }}
        {...other}
      />
    </DataGridStyle>
  );
}

export default StyledDataGrid;
